import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import "../Assets/css/bootstrap.min.css";
import Header from "./header";
import Footer from "./footer";
import { BiRefresh } from "react-icons/bi";
import toast from "react-hot-toast";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import { useCookies } from "react-cookie";
import { ReactSession } from "react-client-session";
import FileDownload from "js-file-download"
import { FileIcon } from 'react-file-icon';
import moment from 'moment';
import Header_mobile from "./header_mobile";
import FileUpload from "./FileUpload";
import { FcFullTrash } from "react-icons/fc";

function Planning() {
    const url = process.env.REACT_APP_URL;
    const headers = JSON.parse(process.env.REACT_APP_CONFIG);
    const [block, setBlock] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const [refreshTab, setRefreshTab] = useState(false);
    const [cookies] = useCookies();
    const [image, setImage] = useState({ preview: '', data: '' })
    const [pending, setPending] = useState(false)
    const [count, setCount] = useState(3);
    const [reportData, setReportData] = useState([]);


    const [modalAddPlanning, setModalAddPlanning] = useState(false);
    const [modalUploadReport, setModalUploadReport] = useState(false);
    const [modalReports, SetModalReports] = useState(false);
    const [modalAddActionSuivre, setModalAddActionSuivre] = useState(false);
    const [modalConfirmDelete, SetModalConfirmDelete] = useState(false);
    const [modalSendRequestDelete, SetModalSendRequestDelete] = useState(false);
    const [modalApprovedChanges, setModalApprovedChanges] = useState(false);
    const [modalChangeStatus, setModalChangeStatus] = useState(false)
    const [modalAddRow, setModalAddRow] = useState(false)

    const [planMonth, setPlanMonth] = useState('01');
    const [planYear, setPlanYear] = useState(0);
    const [planType, setPlanType] = useState("");

    const [detailMonth, setDetailMonth] = useState("");
    const [detailYear, setDetailYear] = useState("");
    const [detailType, setDetailType] = useState("");
    const [detailCreatedDate, setDetailCreatedDate] = useState("");
    const [detailCreatedBy, setDetailCreatedBy] = useState("");
    const [detPlan_code, setDetPlan_code] = useState("");
    const [detPlan_dateFin, setDetPlan_dateFin] = useState("");
    const [detailStatus, setDetailStatus] = useState("");
    const [detailCode, setDetailCode] = useState(-1);
    const [cust_edited, setCust_edited] = useState("");


    const [newAction, setNewAction] = useState("");
    const [newStatus, setNewStatus] = useState(-1);

    const [statusName, setStatusName] = useState("")
    const [codeStatus, setCodeStatus] = useState(-1)
    const [statusCode, setStatusCode] = useState(-1)
    const [newChanges, setNewChanges] = useState("");
    const [detCodeChanges, setDetCodeChanges] = useState(-1);
    const [columnChanges, setColumnChanges] = useState("");

    const [custAddRow, setCustAddRow] = useState("Choose ...");
    const [custCodeAddRow, setCustCodeAddRow] = useState(-1);
    const [datePrevAddRow, setDatePrevAddRow] = useState("");
    const [dateLimAddRow, setDateLimAddRow] = useState("");




    const [actCust, setActCust] = useState(0);
    const [text, setText] = useState("");
    const [detCodeEdited, setDetCodeEdited] = useState(0);
    const [columnEdited, setColumnEdited] = useState("");

    const [cust, setCust] = useState(-1);

    const [custForUpload, setCustForUpload] = useState(0);
    const [detailForDelete, setDetailForDelete] = useState(-1);


    const [listMonth, setListMonth] = useState([]);
    const [listPeriod, setListPeriod] = useState([]);
    const [listCustomer, setListCustomer] = useState([]);
    const [listCustomer2, setListCustomer2] = useState([]);
    const [listDetailPlanning, setListDetailPlanning] = useState([]);
    const [listDetailPlanning2, setListDetailPlanning2] = useState([]);
    const [listPersonOfContact, setListPersonOfContact] = useState([]);
    const [listDetail, setListDetail] = useState([]);
    const [listReport, setListReport] = useState([]);
    const [listPlanAndAction, setListPlanAndAction] = useState([]);
    const [listStat, setListStat] = useState([]);
    const [listUpdates, setListUpdates] = useState([]);
    const [listStatusDetail, setListStatusDetail] = useState([]);
    const [listUpdatesPlanning, setListUpdatesPlanning] = useState([]);
    const [listUpdatesDB, setListUpdatesDB] = useState([])
    const [listActionStatus, setListActionStatus] = useState([])


    const [message_gen, setMessagegen] = useState("");
    const [message_year, setMessageYear] = useState("");
    const [message_type, setMessageType] = useState("");

    const [messageDateLim, setMessageDateLim] = useState("");
    const [messageDatePrev, setMessageDatePrev] = useState("");
    const [messageCust, setMessageCust] = useState("");


    const [currentPage, setCurrentPage] = useState(2);
    const [linePerPage, setlinePerPage] = useState(7);
    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const currentPlanning = listDetailPlanning.slice(indexOfFirstPost, indexOfLastPost);



    const [addPlaning, setAddPlanning] = useState(0);
    const [approvedRequest, setApprovedRequest] = useState(0);
    // const [approvedRequest, setApprovedRequest] = useState(0);

    const getUserPermissions = () => {
        axios.post(url + "?p=getUserPermissions", {
            plan_author: ReactSession.get("sales_id"),
        }, headers).then((response) => {
            if (response.data.status) {
                response.data['result'].map((e) => {
                    switch (e.userPerm_code) {
                        case 400:
                            setAddPlanning(e.userPerm_value);
                            break;
                        case 401:
                            setApprovedRequest(e.userPerm_value);
                            break;
                        default:
                            break;
                    }
                })
            } else {

            }
        })
    }


    useEffect(() => {
        getUserPermissions();
        getMonth();
        getListPeriod();

        setCurrentPage(1);


        setCustForUpload(0);
        setListUpdatesPlanning([]);

        getAllUpdates(detailCode)
        setRefreshTab(false)


    }, [refresh]);

    useEffect(() => {

        setRefreshTab(false)
        getAllUpdates(detailCode);
        getListDetailPlanning(detailCode);
    }, [refreshTab])

    useEffect(() => {
        getActionsStatus();
    }, []);



    const handleTypeClose = () => {
        setModalAddPlanning(false);
        setModalUploadReport(false);
        SetModalReports(false);
        setModalAddActionSuivre(false);
        SetModalConfirmDelete(false);
        SetModalSendRequestDelete(false);
        setModalApprovedChanges(false);
        setModalChangeStatus(false)
        setModalAddRow(false)


        setPlanMonth('01');
        setPlanType("");
        setPlanYear("");
        setCustAddRow("Choose ...");
        setCustCodeAddRow(-1)
        setDateLimAddRow("");
        setDatePrevAddRow("");

        setDetPlan_code(0);
        setDetPlan_dateFin("");
        setActCust(0);
        setText("");
        setColumnEdited("");
        setCust_edited("");
        setCustForUpload("");
        setDetailForDelete(-1);
        setDetailCreatedDate("");

        setNewAction("");
        setNewStatus(-1);
        setCodeStatus(-1);
        setStatusCode(-1);
        setStatusName("");

        setNewChanges("");
        setDetCodeChanges(-1);
        setColumnChanges("");


        setMessageType("");
        setMessageYear("");
        setMessagegen("");
        setMessageDatePrev("");
        setMessageDateLim("");
        setMessageCust("");


        setListCustomer([]);
        setListDetail([]);
        setListPersonOfContact([]);
        setListStat([]);
        setListUpdates([]);
        setListUpdatesPlanning([]);
        setListCustomer2([])

        getListDetailPlanning(detailCode)


        if (refresh) {
            setRefresh(false);
        } else {
            setRefresh(true);
        }
    }
    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <a onClick={() => paginate(1)} href="#" className="">
                                    {"«"}
                                </a>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage == 1 ? (
                                <a href="#" className="" disabled>
                                    {"<"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage - 1)}
                                    href="#"
                                    className=""
                                >
                                    {"<"}
                                </a>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage == number ? (
                                    <a
                                        onClick={() => paginate(number)}
                                        href="#"
                                        className="bg-red"
                                    >
                                        {number}
                                    </a>
                                ) : (
                                    <a onClick={() => paginate(number)} href="#" className="">
                                        {number}
                                    </a>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage == totalPages ? (
                                <a href="#" className="" disabled>
                                    {">"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage + 1)}
                                    href="#"
                                    className=""
                                >
                                    {">"}
                                </a>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={() => paginate(totalPages)} href="#" className="">
                                    {"»"}
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const [isReadOnly, setIsReadOnly] = useState(true)

    const checkState = (status) => {
        if (status === "Not Approved") {
            setIsReadOnly(false);
        } else {
            setIsReadOnly(true)
        }
    }

    const compelteListDetail = (i) => {
        setListUpdatesDB([])
        setListDetailPlanning([])
        if (i !== "") {

            listPeriod.filter(listPeriod => listPeriod.plan_code.toString() == i.toString()).map(filteredPeriod => (
                setDetailMonth(filteredPeriod.month),
                setDetailYear(filteredPeriod.plan_year),
                setDetailType(filteredPeriod.plan_type),
                setDetailCreatedDate((filteredPeriod.plan_created_date)),
                setDetailCreatedBy(filteredPeriod.user_name),
                setDetailStatus(filteredPeriod.status_name),
                setDetailCode(filteredPeriod.plan_code),
                getListDetailPlanning(filteredPeriod.plan_code),
                checkState(filteredPeriod.status_name)
            ))
            getAllUpdates(detailCode)
        } else {
            setDetailCode(-1);
            setDetailMonth("");
            setDetailYear("");
            setDetailType("");
            setDetailCreatedDate((""));
            setDetailCreatedBy("");
            setRefreshTab(true)
        }
        setRefreshTab(true)
        setCurrentPage(1);

    }


    const addPlan = () => {
        var x = 0;
        if (planYear < 2021) { setMessageYear("Incorrect data"); x = 1; }
        else setMessageYear("");
        if (planType === "") { setMessageType("This field cannot be empty"); x = 1; }
        else setMessageType("");

        if (x === 0) {
            axios.post(url + "?p=getAllPlan", {
                plan_month: planMonth,
                plan_year: planYear,
                plan_type: planType
            }, headers).then((response) => {
                if (!response.data.status) {
                    setMessagegen("This data is already exist");
                } else {
                    axios.post(url + "?p=addPlan", {
                        plan_author: ReactSession.get("sales_id"),
                        plan_month: planMonth,
                        plan_year: planYear,
                        plan_type: planType
                    }, headers).then((response) => {
                        if (response.data.status) {
                            addPlanningDetail(response.data.plan_code, planMonth, planYear, planType);
                            notifySuccess("The plannig has been added succesfully");
                        } else {
                            notifyError("This period already exists");
                        }
                    })
                }
            })
        }

    }

    const addPlanningDetail = (plan_code, plan_month, plan_year, plan_type) => {
        axios.post(url + "?p=selectAllCustomer", {
            plan_type: plan_type
        }, headers).then((response) => {
            if (response.data.status) {
                response.data.list ? (response.data.list.map((d, index) => (
                    axios.post(url + "?p=getListPOCforAcust", {
                        cust_code: d.cust_code,
                        limit: true
                    }, headers).then((response) => {
                        if (response.data.status) {
                            var contactCode = 0;
                            setListPersonOfContact(response.data.list);

                            if (response.data.list.length === 0) {
                                contactCode = -1;
                            } else {
                                contactCode = response.data.list[0].contact_code;
                            }
                            axios.post(url + "?p=addDetailPlan", {
                                author: ReactSession.get('sales_id'),
                                plan_code: plan_code,
                                cust_Code: response.data.cust_code,
                                plan_month: plan_month,
                                plan_year: plan_year,
                                contact_code: contactCode
                            }, headers).then((response) => {
                                if (response.data.status) {
                                } else {

                                }
                            })
                        } else {
                        }
                    })
                ))) : (
                    <></>
                )
            } else {

            }
        })
        handleTypeClose();
    }

    const handleApprovePlan = (code, status) => {
        if (code > -1) {
            console.log('ff');
            axios.post(url + "?p=updatePlanStatus", {
                author: ReactSession.get('sales_id'),
                status: status,
                code: code,
                by: cookies.id
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("The plan is approved succesfully");
                    if (status === 1) {
                        setDetailStatus("Pending");
                    } else {
                        setDetailStatus("Approved");
                    }
                    setIsReadOnly(true)
                    if (refresh) {
                        setRefresh(false);
                    } else {
                        setRefresh(true);
                    }
                } else {
                    notifyError("Error with the approvation of planning");
                }
            })
        }
    }


    const getMonth = () => {
        axios.post(url + "?p=getMonth", {
        }, headers).then((response) => {
            if (response.data.status) {
                setListMonth(response.data.list);
            }
        })
    }

    const getListPeriod = () => {
        axios.post(url + "?p=getListPeriod", {
        }, headers).then((response) => {
            if (response.data.status) {
                setListPeriod(response.data.list);
            } else {
            }
        })
    }

    const getListDetailPlanning = (plan_code) => {
        axios.post(url + "?p=getListDetailPlanning", {
            plan_code: plan_code
        }, headers).then((response) => {
            if (response.data.status) {
                setListDetailPlanning(response.data.list);
                setListDetailPlanning2(response.data.list);
            } else {
                setListDetailPlanning([]);
            }
        })
    }

    const beforeToBlur = (text, columnn, codeEdit) => {
        setText(text);
        setColumnEdited(columnn);
        setDetCodeEdited(codeEdit);
        setBlock(1);

    }

    const blur = (old) => {
        if (old === null) {
            old = "";
        }
        if (text !== "" && old !== text) {
            if (columnEdited === "Date") {
                const newList = listDetailPlanning.map((item) => {
                    handleTypeClose();
                    if (item.detail_code === detCodeEdited) {
                        const updateItem = {
                            ...item,
                            plan_date_visite: text
                        };
                        return updateItem;
                    }
                    return item;
                });
                setListDetailPlanning(newList);
            }

            var stars = "";
            if (detailStatus === "Approved") {
                stars += "*"
            }



            axios.post(url + "?p=updateDetail", {
                author: ReactSession.get('sales_id'),
                detCode: detCodeEdited,
                text: text,
                column: columnEdited,
                stars: stars
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("Successfull");
                } else {
                }
            })


            if (detailStatus === "Approved") {
                axios.post(url + "?p=sendRequest", {
                    author: ReactSession.get("sales_id"),
                    planCode: detailCode,
                    detailCode: detCodeEdited,
                    column: columnEdited,
                    oldValue: old,
                    newValue: text,
                }, headers).then((response) => {
                    if (response.data.status) {
                        notifySuccess("The request has been added succesfully")
                    } else {
                        notifyError("Error when we add the request")
                    }
                })
            }


            if (columnEdited === "Contact" || columnEdited === "Status") {

                setRefreshTab(true)
                handleTypeClose();
            }
            setBlock(0);
            setRefreshTab(true)
        }
        setText("");
        getListDetailPlanning(detailCode);
    }

    const getPersons = (cCode, conCode) => {
        axios.post(url + "?p=getListPOCust", {
            cust_code: cCode,
            contact_code: conCode
        }, headers).then((response) => {
            if (response.data.status) {
                setListPersonOfContact(response.data.list);
            } else {
                setListPersonOfContact([]);
            }
        })
    }

    const getListPlanAndCustomer = (codeDet) => {
        axios.post(url + "?p=getListPlanAndCustomer", {
            detCode: codeDet
        }, headers).then((response) => {
            if (response.data.status) {
                setListPlanAndAction(response.data.list);
            } else {
            }
        })
    }

    const getPersonOfContact = (cCode) => {
        axios.post(url + "?p=getPersonOfContact", {
            custCOde: cCode
        }, headers).then((response) => {
            if (response.data.status) {
            } else {

            }
        })
    }


    window.onbeforeunload = confirmExit;
    function confirmExit() {
        if (block === 1) {
            return "You have attempted to leave this page. Are you sure?";
        }
    }


    /* Function for upload */
    const handleFileChange = (e) => {
        const img = {
            preview: URL.createObjectURL(e.target.files[0]),
            data: e.target.files[0],
        }
        setImage(img)
    }

    const upload = () => {
        handleTypeClose();
        setImage("")
        notifySuccess("The file has been upload succesfully");
    }
    /* End function for upload */

    const seeAllReport = (cCode) => {
        axios.post(url + "?p=allReport", {
            cust_code: cCode,
            detail_code: detailCode
        }, headers).then((response) => {
            if (response.data.status) {
                setListReport(response.data.list);
            } else {
            }
        })
    }

    const getFile = (cName, cDate, rCode, cCode, dCode, ext) => {
        axios({
            url: url + "/getFile",
            method: "GET",
            responseType: "blob",
            params: {
                rCode: rCode,
                cCode: cCode,
                dCode: dCode,
                ext: ext
            }
        }, headers).then((response) => {
            FileDownload(response.data, cName + "_" + cDate + "_report." + ext);
        });
    }
    const getActStat = (stat) => {
        axios.post(url + "?p=getActStat", {
            stat: stat
        }, headers).then((response) => {
            if (response.data.status) {
                setListStat(response.data.list);
            }
        })
    }

    const addAction = () => {
        if (newAction !== "") {
            axios.post(url + "?p=addAction", {
                author: ReactSession.get('sales_id'),
                action_name: newAction,
                action_status: newStatus,
                detail_code: detCodeEdited
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("The action has been added succesfully")
                    getListPlanAndCustomer(detCodeEdited);
                    setNewAction("");
                } else {
                    notifyError("Error when we add the new action")
                }
            })
        }
    }

    const deleteAction = (action_code) => {
        axios.post(url + "?p=deleteAction", {
            author: ReactSession.get('sales_id'),
            action_code: action_code
        }, headers).then((response) => {
            if (response.data.status) {
                notifySuccess("The action has been deleted succesfully")
                getListPlanAndCustomer(detCodeEdited);
            } else {
                notifyError("Error When we delete the action");
            }
        })
    }

    const updateStatusAction = (action_code, newStatus) => {
        axios.post(url + "?p=updateStatusAction", {
            author: ReactSession.get('sales_id'),
            action_code: action_code,
            newStatus: newStatus
        }, headers).then((response) => {
            if (response.data.status) {
                notifySuccess("Update succesfull");
            } else {
                notifyError("Error when we update the status")
            }
        })
    }

    const deletePlanningRequest = () => {
        var sRequest = false;
        if (detailStatus !== 'Not Approved') {
            sRequest = true;
        }

        if (approvedRequest === 1) {
            deletePlanning()
        } else {
            axios.post(url + "?p=deletePlanningRequest", {
                author: ReactSession.get('sales_id'),
                detailForDelete: detailForDelete,
                task: "request",
                sRequest: sRequest
            }, headers).then((response) => {
                if (response.data.status) {
                    if (sRequest) {
                        notifySuccess("The request has been sended")
                    } else {
                        notifySuccess("The planning has been deleted")
                    }
                    setRefreshTab(true)
                    handleTypeClose();
                } else {
                    notifyError("Error when we delete the planning")
                }
            })
        }

    }

    const deletePlanning = () => {
        if (approvedRequest === 1) {
            axios.post(url + "?p=deletePlanningRequest", {
                author: ReactSession.get('sales_id'),
                detailForDelete: detailForDelete,
                task: "delete"
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("The planning has been sended")
                    setRefreshTab(true)
                    handleTypeClose();
                } else {
                    notifyError("Error when we delete the planning")
                }
            })
        } else {
            handleTypeClose();
            notifyError("You have not the permission")
        }

    }

    const declineDelete = () => {
        if (approvedRequest === 1) {
            axios.post(url + "?p=deletePlanningRequest", {
                author: ReactSession.get('sales_id'),
                detailForDelete: detailForDelete,
                task: "update"
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("The planning has been cancelled")
                    setRefreshTab(true)
                    handleTypeClose();
                } else {
                    notifyError("Error when we delete the planning")
                }
            })
        } else {
            handleTypeClose();
            notifyError("You have not the permission")
        }

    }

    const getAllUpdates = () => {
        axios.post(url + "?p=getAllUpdates", {
            planCode: detailCode
        }, headers).then((response) => {
            if (response.data.status) {
                setListUpdatesDB(response.data.list)
            } else {
                setListUpdatesDB([])
            }
        })
    }

    const checkUpdates = (detCode, column) => {
        if (approvedRequest === 1) {
            axios.post(url + "?p=checkUpdates", {
                planCode: detailCode,
                detailCode: detCode,
                column: column
            }, headers).then((response) => {
                if (response.data.status) {
                    if (response.data.list.length === 0) {
                        notifyError("No updates Here")
                    } else {
                        setDetCodeChanges(detCode);
                        setColumnChanges(column)
                        setListUpdates(response.data.list);
                        setModalApprovedChanges(true);
                    }
                } else {
                }
            })
        }
    }

    const approvedChanges = () => {
        if (approvedRequest === 1) {
            var changes = "";
            if (newChanges === "") {
                changes += listUpdates[0].update_newValue;
            } else {
                changes += newChanges
            }
            axios.post(url + "?p=approvedChanges", {
                changes: changes,
                planCode: detailCode,
                detCode: detCodeChanges,
                column: columnChanges
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("Changes has been accepted");
                    getListDetailPlanning(detailCode);
                    handleTypeClose();
                } else {
                    notifyError("Erro when we change the value");
                }
            })
        } else {
            handleTypeClose()
            notifyError("You have not the permission")
        }

    }

    const declineChanges = () => {
        if (approvedRequest === 1) {
            var value = "";
            if (listUpdates[0].update_oldValue[listUpdates[0].update_oldValue.length - 1] === "*") {
                value += listUpdates[0].update_oldValue.substring(0, listUpdates[0].update_oldValue.length - 1);
            } else {
                value += listUpdates[0].update_oldValue;
            }

            axios.post(url + "?p=approvedChanges", {
                changes: value,
                planCode: detailCode,
                detCode: detCodeChanges,
                column: columnChanges,
                task: "decline"
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("The changement has been declined");
                    getListDetailPlanning(detailCode);
                    handleTypeClose();
                } else {
                    notifyError("Error when we decline the changes");
                }
            })
        } else {
            handleTypeClose()
            notifyError("You have not the permission")
        }

    }

    const getListDetailStatus = (stat) => {
        axios.post(url + "?p=getListDetailStatus", {
            stat: stat
        }, headers).then((response) => {
            if (response.data.status) {
                setListStatusDetail(response.data.list);
            }
        })
    }

    const changeValue = (detCode, column, value, index) => {
        setListDetailPlanning(listDetailPlanning.map(detail => {
            if (detail.detail_code === detCode) {
                if (column === "ButVisite") {
                    if (listDetailPlanning2[index].plan_but_visite !== value) {
                        addToUpdates(detCode, column, value, listDetailPlanning2[index].plan_but_visite);
                    } else {
                        removeFromUpdates(detCode, column);
                    }
                    return { ...detail, plan_but_visite: value };
                } else if (column === "AgentCharge") {
                    if (listDetailPlanning2[index].plan_agent_charge !== value) {
                        addToUpdates(detCode, column, value, listDetailPlanning2[index].plan_agent_charge);
                    } else {
                        removeFromUpdates(detCode, column);
                    }
                    return { ...detail, plan_agent_charge: value };
                } else if (column === "Remarque") {
                    if (listDetailPlanning2[index].plan_remarque !== value) {
                        addToUpdates(detCode, column, value, listDetailPlanning2[index].plan_remarque);
                    } else {
                        removeFromUpdates(detCode, column);
                    }
                    return { ...detail, plan_remarque: value };
                } else if (column === "Priorite") {
                    if (listDetailPlanning2[index].plan_priorite_action !== value) {
                        addToUpdates(detCode, column, value, listDetailPlanning2[index].plan_priorite_action);
                    } else {
                        removeFromUpdates(detCode, column);
                    }
                    return { ...detail, plan_priorite_action: value };
                } else if (column === "PersonneCharge") {
                    if (listDetailPlanning2[index].plan_personne_charge !== value) {
                        addToUpdates(detCode, column, value, listDetailPlanning2[index].plan_personne_charge);
                    } else {
                        removeFromUpdates(detCode, column);
                    }
                    return { ...detail, plan_personne_charge: value };
                } else if (column === "Resultat") {
                    if (listDetailPlanning2[index].plan_resultat_action !== value) {
                        addToUpdates(detCode, column, value, listDetailPlanning2[index].plan_resultat_action);
                    } else {
                        removeFromUpdates(detCode, column);
                    }
                    return { ...detail, plan_resultat_action: value };
                } else if (column === "dateLimite") {
                    if (listDetailPlanning2[index].plan_date_limite !== value) {
                        addToUpdates(detCode, column, value, listDetailPlanning2[index].plan_date_limite);
                    } else {
                        removeFromUpdates(detCode, column);
                    }
                    return { ...detail, plan_date_limite: value };
                } else if (column === "dateVisite") {
                    if (listDetailPlanning2[index].plan_date_visite !== value) {
                        addToUpdates(detCode, column, value, listDetailPlanning2[index].plan_date_visite);
                    } else {
                        removeFromUpdates(detCode, column);
                    }
                    return { ...detail, plan_date_visite: value };
                }
            } else {
                return detail;
            }
        }));
    }

    const addToUpdates = (detCode, column, value, oldValue) => {
        const found = listUpdatesPlanning.find(obj => {
            return (obj.code === detCode && obj.column === column)
        })
        if (found === null || found === undefined) {
            setListUpdatesPlanning(current => [...current,
            {
                code: detCode,
                column: column,
                value: value,
                old: oldValue
            }
            ])
        }
        else {
            const found = listUpdatesPlanning.map((obj) => {
                if (obj.code === detCode && obj.column === column)
                    return { ...obj, value: value }
                return { ...obj }
            })
            setListUpdatesPlanning(found)
        }
    }

    const removeFromUpdates = (detCode, column) => {
        setListUpdatesPlanning((current) =>
            current.filter((obj) => (obj.code !== detCode && obj.column !== column))
        );
    }

    const updateDetails = (value, column, detCode, task, old) => {

        if (task !== "all") {
            setListDetailPlanning(listDetailPlanning.map(detail => {
                if (detail.detail_code === detCode) {
                    if (column === "ButVisite") {
                        return { ...detail, plan_but_visite: detail.plan_but_visite + "*" };
                    } else if (column === "AgentCharge") {
                        return { ...detail, plan_agent_charge: detail.plan_agent_charge };
                    } else if (column === "Remarque") {
                        return { ...detail, plan_remarque: detail.plan_remarque };
                    } else if (column === "Priorite") {
                        return { ...detail, plan_priorite_action: detail.plan_priorite_action };
                    } else if (column === "PersonneCharge") {
                        return { ...detail, plan_personne_charge: detail.plan_personne_charge };
                    } else if (column === "Resultat") {
                        return { ...detail, plan_resultat_action: detail.plan_resultat_action };
                    } else if (column === "dateLimite") {
                        return { ...detail, plan_date_limite: detail.plan_date_limite + "*" };
                    } else if (column === "dateVisite") {
                        return { ...detail, plan_date_visite: detail.plan_date_visite + "*" };
                    } else if (column === "Status") {
                        return { ...detail, plan_status_2: value + "*" };
                    }
                } else {
                    return detail;
                }
            }));

            if (detailStatus === 'Not Approved' || column === "AgentCharge" || column === "Remarque" || column === "Priorite" || column === "PersonneCharge" || column === "Resultat" || approvedRequest == 1) {
                axios.post(url + "?p=UpdateDetails", {
                    author: ReactSession.get('sales_id'),
                    value: value,
                    column: column,
                    detCode: detCode,
                    position: "high"
                }, headers).then((response) => {
                    if (response.data.status) {
                        updateList2(value, column, detCode, task);
                    } else {

                    }
                })

            } else {
                axios.post(url + "?p=UpdateDetails", {
                    author: ReactSession.get('sales_id'),
                    value: value + "*",
                    column: column,
                    detCode: detCode
                }, headers).then((response) => {
                    if (response.data.status) {
                        updateList2(value, column, detCode, task);
                    } else {

                    }
                })

                axios.post(url + "?p=sendRequest", {
                    author: ReactSession.get("sales_id"),
                    planCode: detailCode,
                    detailCode: detCode,
                    column: column,
                    oldValue: old,
                    newValue: value,
                }, headers).then((response) => {
                    if (response.data.status) {
                        notifySuccess("The request has been added succesfully")
                    } else {
                        notifyError("Error when we add the request")
                    }
                })
            }




            // axios.post()
        } else {
            listUpdatesPlanning.map((d, index) => {
                setListDetailPlanning(listDetailPlanning.map(detail => {
                    if (detail.detail_code === d.code) {
                        if (d.column === "ButVisite") {
                            return { ...detail, plan_but_visite: d.value + "*" };
                        } else if (d.column === "AgentCharge") {
                            return { ...detail, plan_agent_charge: d.value };
                        } else if (d.column === "Remarque") {
                            return { ...detail, plan_remarque: d.value };
                        } else if (d.column === "Priorite") {
                            return { ...detail, plan_priorite_action: d.value };
                        } else if (d.column === "PersonneCharge") {
                            return { ...detail, plan_personne_charge: d.value };
                        } else if (d.column === "Resultat") {
                            return { ...detail, plan_resultat_action: d.value };
                        } else if (d.column === "dateLimite") {
                            return { ...detail, plan_date_limite: detail.plan_date_limite + "*" };
                        } else if (d.column === "dateVisite") {
                            return { ...detail, plan_date_visite: detail.plan_date_visite + "*" };
                        } else if (column === "Status") {
                            return { ...detail, plan_status_2: value + "*" };
                        }
                    } else {
                        return detail;
                    }
                }));
                if (detailStatus === 'Not Approved' || d.column === "AgentCharge" || d.column === "Remarque" || d.column === "Priorite" || d.column === "PersonneCharge" || d.column === "Resultat" || approvedRequest == 1) {
                    axios.post(url + "?p=UpdateDetails", {
                        author: ReactSession.get('sales_id'),
                        value: d.value,
                        column: d.column,
                        detCode: d.code,
                        position: "high"
                    }, headers).then((response) => {
                        if (response.data.status) {
                            updateList2(d.value, d.column, d.code, task);
                        } else {

                        }
                    })
                } else {
                    axios.post(url + "?p=UpdateDetails", {
                        author: ReactSession.get('sales_id'),
                        value: d.value + "*",
                        column: d.column,
                        detCode: d.code
                    }, headers).then((response) => {
                        if (response.data.status) {
                            updateList2(d.value + "*", d.column, d.code, task);
                        } else {

                        }
                    })

                    axios.post(url + "?p=sendRequest", {
                        author: ReactSession.get("sales_id"),
                        planCode: detailCode,
                        detailCode: d.code,
                        column: d.column,
                        oldValue: d.old,
                        newValue: d.value
                    }, headers).then((response) => {
                        if (response.data.status) {
                            notifySuccess("The request has been added succesfully")
                        } else {
                            notifyError("Error when we add the request")
                        }
                    })
                }
            })
        }
        getAllUpdates(detailCode)
        getListDetailPlanning(detailCode)
        setRefreshTab(true)
    }

    const updateList2 = (value, column, detCode, task) => {


        if (task != "all") {
            setListDetailPlanning2(listDetailPlanning2.map(detail => {
                if (detail.detail_code === detCode) {
                    if (column === "ButVisite") {
                        return { ...detail, plan_but_visite: value };
                    } else if (column === "AgentCharge") {
                        return { ...detail, plan_agent_charge: value };
                    } else if (column === "Remarque") {
                        return { ...detail, plan_remarque: value };
                    } else if (column === "Priorite") {
                        return { ...detail, plan_priorite_action: value };
                    } else if (column === "PersonneCharge") {
                        return { ...detail, plan_personne_charge: value };
                    } else if (column === "Resultat") {
                        return { ...detail, plan_resultat_action: value };
                    } else if (column === "dateLimite") {
                        return { ...detail, plan_date_limite: value };
                    } else if (column === "dateVisite") {
                        return { ...detail, plan_date_visite: value };
                    } else if (column === "Status") {
                        return { ...detail, plan_status_2: value };
                    }
                } else {
                    return detail;
                }
            }));

            let arr1 = listUpdatesPlanning.filter((item) => item.code !== detCode);
            let arr2 = listUpdatesPlanning.filter((item) => item.code === detCode);
            arr2 = arr2.filter((item) => item.column !== column);

            setListUpdatesPlanning([...arr1, ...arr2])

        } else {
            setListDetailPlanning2(listDetailPlanning)
            setListUpdatesPlanning([])
        }

    }



    const validateChanges = (task) => {
        var value = "";

        listUpdatesDB.map((d, index) => {
            if (task === "confirm") {
                if (d.update_newValue !== null) {
                    value = d.update_newValue;
                } else {
                    value = "";
                }
            } else {
                if (d.update_oldValue !== null) {
                    value = d.update_oldValue;
                } else {
                    value = "";
                }
            }

            axios.post(url + "?p=approvedChanges", {
                author: ReactSession.get('sales_id'),
                changes: value,
                planCode: detailCode,
                detCode: d.update_detailCode,
                column: d.update_column
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("Changes has been accepted");
                    getListDetailPlanning(detailCode);
                    handleTypeClose();
                } else {
                    notifyError("Erro when we change the value");
                }
            })

        })
        setRefreshTab(true);
    }

    const changeStatus = () => {
        var val = -1;
        if (statusCode === "1") {
            val = 2
        } else {
            val = 1
        }


        updateDetails(val, "Status", codeStatus, "", statusCode)

        setRefreshTab(true);
        handleTypeClose()
    }


    const getAllCustomer = () => {
        axios.post(url + "?p=getAllCustomer", {
            detType: detailType,
            plan_code: detailCode
        }, headers).then((response) => {
            setListCustomer(response.data.list)
            if (response.data.list.length > 0) {
                // getDetailCustomer(response.data.list[0].cust_code);
                setListCustomer2(
                    response.data.list[0],
                )
                setCustCodeAddRow(response.data.list[0].cust_code);
                getPersonOfContact(response.data.list[0].cust_code);
            }
        })
    }

    const getDetailCustomer = (x) => {
        setListCustomer2(
            listCustomer.filter((filtre) => filtre.cust_code + "" === x + ""),
        )

        if (listCustomer2.length > 0) {
            setCustCodeAddRow(listCustomer2[0].cust_code);
            getPersonOfContact(listCustomer2[0].cust_code)
        } else {

        }
    }

    const addRow = () => {
        var t = true;
        if (custAddRow === "") { t = false; setMessageCust("This field cannot be empty"); } else { setMessageCust("") }
        if (datePrevAddRow === "") { t = false; setMessageDatePrev("This field cannot be empty"); } else { setMessageDatePrev("") }
        if (dateLimAddRow === "") { t = false; setMessageDateLim("This field cannot be empty"); } else { setMessageDateLim("") }

        if (listCustomer2.length < 1) {
            t = false
            setMessageCust("Customer not found")
        }

        if (t) {
            axios.post(url + "?p=addRow", {
                author: ReactSession.get('sales_id'),
                planCode: detailCode,
                custCode: custCodeAddRow,
                datePrev: datePrevAddRow,
                dateLim: dateLimAddRow
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("The row has been added succesfully")
                    handleTypeClose()
                } else {
                    notifyError("Error when we add the new row")
                }
            })
        }

    }

    const getActionsStatus = () => {
        axios.post(url + "?p=getActionsStatus", {
        }, headers).then((response) => {
            setListActionStatus(response.data.list);
        })
    }

    useEffect(() => {
        let timer;
        if (pending) {
            timer = setInterval(() => {
                setCount((c) => c - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [pending]);

    useEffect(() => {
        if (count === 0) {
            setPending(false);
            setCount(3);
            deleteReport(reportData);
        }
    }, [count]);

    const deleteReport = (report) => {
        const fileName = report.report_code + '-report-' + report.cust_code + '-' + report.detail_code + '.' + report.report_ext;

        if (count === 0) {
            axios.post(url + '?p=deleteReport', {
                author: ReactSession.get('sales_id'),
                fileName,
                report
            }, headers).then((response) => {
                if (response.data.status) {
                    seeAllReport(report.cust_code);
                    notifySuccess('File deleted')
                } else {
                    console.log(response.data);
                }
                setPending(false)
            })
        }
    }

    return (
        <>
            <Header />
            <Header_mobile />

            <Modal show={modalAddPlanning} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Planning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="row g-3 ml-15 text-center">
                        <div className="form-group mb-4 mt-3 col-md-4">
                            <label className="font-weight-bold">
                                Month<span className="text-danger pl-3">*</span>
                            </label>
                            <select
                                id="type"
                                onChange={(e) => {
                                    setPlanMonth(e.target.value);
                                }}
                                value={planMonth}
                                name="plan-month"
                                className="form-control rounded-0 cursor-pointer"
                            >
                                {
                                    listMonth ? (listMonth.map((d, index) => (
                                        <option value={d.month_no} key={index}>
                                            {d.month}
                                        </option>
                                    ))
                                    ) : (
                                        <>

                                        </>
                                    )
                                }
                            </select>

                        </div>

                        <div className="col-md-3">
                            <label className="font-weight-bold">
                                Year<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="plan-year"
                                onChange={(e) => {
                                    setPlanYear(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0 "
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_year}
                            </div>

                        </div>

                        < div className="form-group mb-4 col-md-3">
                            <label className="font-weight-bold">
                                Type
                                <span className="text-danger pl-3"></span>
                            </label>
                            <select
                                id="type"
                                onChange={(e) => {
                                    setPlanType(e.target.value);
                                }}
                                value={planType}
                                name="type"
                                className="form-control rounded-0 cursor-pointer"
                            >
                                <option key={0} value="-1">Choose ...</option>
                                <option key={1} value="TBR">TBR</option>
                                <option key={2} value="OTR">OTR</option>
                                <option key={3} value="OIL">OIL</option>
                            </select>
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_type}
                            </div>
                        </div>
                        <div id="ticketprio-error" className="text-danger pt-2">
                            {message_gen}
                        </div>


                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleTypeClose} className="btn btn-secondary">Close</button>
                    <button type="button" onClick={addPlan} className="btn btn-success">Add PLANNING</button>

                </Modal.Footer>
            </Modal>

            <Modal show={modalUploadReport} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title >UPLOAD REPORT </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FileUpload cCode={cust} dCode={detailCode} author={ReactSession.get('sales_id')} />
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleTypeClose} className="btn btn-secondary">Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalReports} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>REPORTS</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{

                    height: '400px',
                    overflow: 'auto',

                }}>
                    <table className="table  h-5px overflow-auto" >
                        <thead className="position-fixe">
                            <tr>
                                <th></th>
                                <th>Type</th>
                                <th>File Name</th>
                                <th></th>
                            </tr>
                        </thead>
                        {listReport ? (listReport.map((d, index) => (
                            <tbody key={index}>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td style={{ 'width': '10px' }} className='p-1 cursor-pointer' onClick={() => { getFile(d.cust_name, d.report_date.substring(0, 10), d.report_code, d.cust_code, d.detail_code, d.report_ext) }}>
                                        {

                                            d.report_ext === "pdf" ? (
                                                <FileIcon extension='pdf' foldColor='red' labelColor='red' glyphColor="red" type='acrobat' />
                                            ) : d.report_ext === "docx" || d.report_ext === "doc" ? (
                                                <FileIcon extension='docx' foldColor='blue' labelColor='blue' glyphColor="blue" type='document' />
                                            ) : d.report_ext === "xls" || d.report_ext === "xlsx" ? (
                                                <FileIcon extension='xlsx' foldColor='green' labelColor='green' glyphColor="green" type='spreadsheet' />
                                            ) : (
                                                <FileIcon extension='IMG' foldColor='orange' labelColor='orange' glyphColor="orange" type='image' />
                                            )

                                        }
                                    </td>

                                    <td className="cursor-pointer" onClick={() => { getFile(d.cust_name, d.report_date.substring(0, 10), d.report_code, d.cust_code, d.detail_code, d.report_ext) }}><label>{d.cust_name + "_" + d.report_date.substring(0, 10) + "_" + d.report_date.substring(11, 19) + "." + d.report_ext}</label></td>
                                    <td>
                                        {pending && d.report_code === reportData.report_code ?
                                            <p className="cursor-pointer" onClick={() => { setPending(false); setCount(3) }}>
                                                Annuler ... {count}
                                            </p>
                                            :
                                            <FcFullTrash size={20} className="cursor-pointer " onClick={() => { setReportData(d); setPending(true) }} />
                                        }
                                    </td>
                                </tr>


                            </tbody>
                        ))
                        ) : (
                            <></>
                        )}
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleTypeClose} className="btn btn-secondary">Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalAddActionSuivre} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter action a suivre pour {cust_edited}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="h-full overflow-auto">
                        <table id="customers max-h-300px" className="table ">
                            <thead className="position-fixe">
                                <tr>
                                    <th scope="col" style={{ width: '100px' }} className="text-left">
                                        Action
                                    </th>

                                    <th scope="col" style={{ width: '120px' }} className="text-left">
                                        Status
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="resultdata">
                                {listPlanAndAction.length > 0 ? (
                                    listPlanAndAction.map((d, index) => (
                                        <tr key={index}>
                                            <td id="tb-created-date" className="text-left">
                                                <input onChange={(e) => { setNewAction(e.target.value) }} value={d.action_name} type="text" style={{ border: "none" }} />
                                            </td>
                                            <td>
                                                <select onChange={(e) => {
                                                    setNewStatus(e.target.value);
                                                    updateStatusAction(d.action_code, e.target.value)
                                                }} onClick={() => { getActStat(d.action_status) }} className="cursor-pointer p-2" style={{ border: "none" }}>
                                                    <option key={0} value={d.action_status}>{d.actStat_name}</option>
                                                    {listStat ? listStat.map((d, index) => (
                                                        <option key={d.actStat_code} value={d.actStat_code}>{d.actStat_name}</option>
                                                    )) : (
                                                        <></>
                                                    )}
                                                </select>
                                            </td>
                                            <td><i className="fa la-trash cursor-pointer" onClick={() => { deleteAction(d.action_code) }}></i></td>
                                        </tr>
                                    ))
                                ) : (
                                    <></>
                                )}

                                <tr>
                                    <td id="tb-created-date" className="text-left">
                                        <input onChange={(e) => { setNewAction(e.target.value) }} type="text" style={{ border: "none" }} value={newAction} /*onBlur={(e) => { addAction(); e.target.value = "" }}*/ />
                                    </td>
                                    <td>
                                        <select onChange={(e) => {
                                            setNewStatus(e.target.value)
                                        }} className="cursor-pointer p-2" style={{ border: "none" }}>
                                            {listActionStatus ? listActionStatus.map((d, index) => (
                                                <option key={d.actStat_code} value={d.actStat_code}>{d.actStat_name}</option>
                                            )) : (
                                                <></>
                                            )}
                                        </select>
                                    </td>
                                    {newAction !== "" ? (
                                        <>
                                            <div className="cursor-pointer mt-2" onClick={() => { addAction(); setNewAction("") }}>
                                                <i className="fa fa-paper-plane" ></i>
                                            </div>
                                        </>
                                    ) : ""}
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary w-25" onClick={() => { getListDetailPlanning(detailCode); handleTypeClose() }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalSendRequestDelete} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete this planning </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="h-full overflow-auto">
                        <label>Are you sure ?? </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary w-25" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-primary w-25" onClick={deletePlanningRequest}>Delete</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalConfirmDelete} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete this planning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="h-full overflow-auto">
                        <label>Confirm the suppression ?? </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary w-25" onClick={handleTypeClose}>Cancel</button>
                    <button type="button" className="btn btn-info w-25" onClick={declineDelete}>Decline</button>
                    <button type="button" className="btn btn-primary w-25" onClick={deletePlanning}>Confirm</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalApprovedChanges} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>APPROVE THE NEXT MODIFICATION ? </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="h-full overflow-auto">
                        <table id="customers max-h-300px" className="table ">
                            <thead className="position-fixe">
                                <tr>
                                    <th scope="col" style={{ width: '100px' }} className="text-left">
                                        Author
                                    </th>

                                    <th scope="col" style={{ width: '120px' }} className="text-left">
                                        Old value
                                    </th>
                                    <th scope="col" style={{ width: '120px' }} className="text-left">
                                        New Value
                                    </th>
                                </tr>
                            </thead>
                            <tbody id="resultdata">
                                {listUpdates.length > 0 ? (
                                    <>
                                        <tr>
                                            <td id="tb-created-date" className="text-left">
                                                <label>{listUpdates[0].user_name}</label>
                                                {/* <input onChange={(e) => { setNewAction(e.target.value) }} type="text" style={{ border: "none" }} onBlur={(e) => { addAction(); e.target.value = "" }} /> */}
                                            </td>
                                            <td>
                                                <label>{
                                                    listUpdates[0].update_oldValue[listUpdates[0].update_oldValue.length - 1] === "*" ?
                                                        listUpdates[0].update_oldValue.substring(0, listUpdates[0].update_oldValue.length - 1) === "1" ? "Pending" :
                                                            listUpdates[0].update_oldValue.substring(0, listUpdates[0].update_oldValue.length - 1) === "2" ? "Completed" :
                                                                listUpdates[0].update_oldValue.substring(0, listUpdates[0].update_oldValue.length - 1)
                                                        : listUpdates[0].update_oldValue === "1" ? "Pending" :
                                                            listUpdates[0].update_oldValue === "2" ? "Completed" :
                                                                listUpdates[0].update_oldValue}</label>
                                            </td>
                                            <td>
                                                <input type="text" onChange={(e) => { setNewChanges(e.target.value) }} defaultValue={listUpdates[0].update_newValue === "1" ? "Pending" : listUpdates[0].update_newValue === "2" ? "Completed" : listUpdates[0].update_newValue} />
                                            </td>
                                        </tr>
                                    </>
                                ) : (
                                    <></>
                                )}


                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary w-25" onClick={declineChanges}>Decline</button>
                    <button type="button" className="btn btn-primary w-25" onClick={approvedChanges}>Approved</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalChangeStatus} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Change status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="h-full overflow-auto">
                        <label>Actual status is <span>{statusName} </span> </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary w-25" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-primary w-full" onClick={changeStatus}>Change to {statusCode === "1" ? "Completed" : "Pending"}</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalAddRow} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add a row</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>

                        <div className="form-group mb-1 mt-1 row g-1">
                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    Customer name<span className="text-danger pl-3">*</span>
                                </label>
                                <select
                                    id="type"
                                    onChange={(e) => {
                                        setCustCodeAddRow(e.target.value);
                                        getDetailCustomer(e.target.value)
                                    }}
                                    name="cust_name"
                                    className="form-control rounded-0 cursor-pointer"
                                >
                                    {
                                        listCustomer ? (listCustomer.map((d, index) => (
                                            <option value={d.cust_code} key={index}>
                                                {d.cust_code + "_" + d.cust_name}
                                            </option>
                                        ))
                                        ) : (
                                            <></>
                                        )
                                    }
                                </select>
                            </div>

                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    Date Prevue<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    onChange={(e) => { setDatePrevAddRow(e.target.value) }}
                                    id="ticket-subject"
                                    name="subject"
                                    type="date"
                                    className="form-control rounded-0"
                                />
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageDatePrev}
                                </div>
                            </div>


                        </div>

                        <div className="form-group mb-1 mt-1 row g-2">
                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    date limite<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    onChange={(e) => { setDateLimAddRow(e.target.value) }}
                                    type="date"
                                    id="ticket-subject"
                                    name="subject"
                                    className="form-control rounded-0"
                                />
                            </div>
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {messageDateLim}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Annuler</button>
                    <button type="button" className="btn btn-success" onClick={addRow}>Envoyer</button>
                </Modal.Footer>
            </Modal>

            <div
                className="d-flex flex-column flex-column-fluid "
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}
            >
                <div className="col-xl-12 center-block " align="center" style={{ backgroundColor: "#eef0f8" }} >
                    <div className="col-xl-8 bg-white mt-50 center-block min-h-650px" align="center" >
                        <div className="pt-10 w-100 h-100px d-flex" style={{ marginLeft: "0.8rem" }}>
                            <div className="container text-left">
                                <div className="row d-flex text-left">

                                    <div className="col-12 col-lg-4 col-sm-12 center-block" >
                                        <h3 className="float-left pt-2 pr-3">Planning list </h3>

                                        <span className="float-left">
                                            <BiRefresh
                                                onClick={() => {
                                                    getAllUpdates(detailCode)
                                                    getListDetailPlanning(detailCode)
                                                }}
                                                className="cursor-pointer display-4"
                                            />
                                        </span>
                                    </div>
                                    <div className="col-5 col-lg-4 col-sm-6 ">
                                        <label className="font-weight-bold ml-10">
                                            Select a period
                                        </label>
                                        <div className="input-group mb-3 col-md-8 ml">
                                            <select
                                                id="type"
                                                onChange={(e) => {
                                                    compelteListDetail(e.target.value);
                                                }}
                                                name="plan-period"
                                                className="form-control rounded-0 cursor-pointer"
                                            >
                                                <option key={0} value="">Choose ...</option>
                                                {
                                                    listPeriod ? (listPeriod.map((d, index) => (
                                                        <option value={d.plan_code} key={d.plan_code}>
                                                            {d.month + "/" + d.plan_year + "( " + d.plan_type + " )"}
                                                        </option>
                                                    ))
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </select>
                                            <label className="input-group-text" htmlFor="inputGroupSelect02">Search</label>
                                        </div>
                                    </div>

                                    <div className="col-7 col-lg-4 col-sm-6 mt-8 d-flex">
                                        <div className="col-lg-6 align-middle">
                                            {addPlaning === 1 ? (
                                                <button
                                                    id="crticket-btn"
                                                    className="btn btn-defaut text-white bg-red font-weight-bold float-right   "
                                                    data-toggle="modal"
                                                    data-target="#addticketModal"
                                                    onClick={() => { setModalAddPlanning(true) }}
                                                >
                                                    Add a planning
                                                </button>
                                            ) : (<></>)}
                                        </div>
                                        <div className="col-lg-6">
                                            {detailCode !== -1 ? (
                                                <button onClick={() => { getAllCustomer(); setModalAddRow(true); }} className="btn btn-defaut text-white bg-info float-right">Add a row</button>
                                            ) : (<></>)}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="h-full pb-2 overflow-auto mt-20">
                            <div className="px-5 mt-2">
                                <div className="table text-left p-2 px-4 border border-black shadow">
                                    <div className="row m-0 p-0 border-bottom">
                                        <div className="col">
                                            <div className="col p-0 m-0 ">
                                                <label className="font-weight-bold">
                                                    Month
                                                </label>
                                            </div>

                                            <div className="col p-0 m-0">
                                                <label id="user-fullname" name="subject" className="rounded-0" >
                                                    {detailMonth}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="col p-0 m-0 ">
                                                <label className="font-weight-bold">
                                                    Year
                                                </label>
                                            </div>

                                            <div className="col p-0 m-0">
                                                <label id="user-fullname" name="subject" className="rounded-0" >
                                                    {detailYear}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="col p-0 m-0 ">
                                                <label className="font-weight-bold">
                                                    Type
                                                </label>
                                            </div>

                                            <div className="col p-0 m-0">
                                                <label id="user-fullname" name="subject" className="rounded-0" >
                                                    {detailType}
                                                </label>
                                            </div>
                                        </div>
                                        {listUpdatesPlanning.length > 1 ? (
                                            <div className="col"></div>
                                        ) : (<></>)}

                                        {listUpdatesDB.length > 1 && approvedRequest === 1 ? (
                                            <div className="col">
                                                <div className="col p-0 m-0 ">
                                                    <label className="font-weight-bold">

                                                    </label>
                                                </div>

                                                <div className="col p-0 m-0">
                                                    <label id="user-fullname" name="subject" className="rounded-0" >
                                                        <button className="btn btn-warning" onClick={() => { validateChanges("confirm"); setRefreshTab(true) }}>Confirm all changes</button>
                                                    </label>
                                                </div>
                                            </div>
                                        ) : (<></>)}

                                    </div>
                                    <div className="row m-0 p-0 mt-1">
                                        <div className="col">
                                            <div className="col p-0 m-0 ">
                                                <label className="font-weight-bold">
                                                    Created by
                                                </label>
                                            </div>

                                            <div className="col p-0 m-0">
                                                <label id="user-fullname" name="subject" className="rounded-0" >
                                                    {detailCreatedBy}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="col p-0 m-0 ">
                                                <label className="font-weight-bold">
                                                    Created date
                                                </label>
                                            </div>

                                            <div className="col p-0 m-0">
                                                <label id="user-fullname" name="subject" className="rounded-0" >
                                                    {detailCreatedDate === "" ? (<></>) : (<>{moment(detailCreatedDate).format('LL')}</>)}
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col">
                                            <div className="col p-0 m-0 ">
                                                <label className="font-weight-bold">
                                                    Status
                                                </label>
                                            </div>

                                            <div className="col p-0 m-0">
                                                <label id="user-fullname" name="subject" className="rounded-0" >
                                                    {detailStatus === 'Pending' || detailStatus == 'Not Approved' && detailCode !== -1 && approvedRequest == 1 ? (
                                                        (<button className="btn btn-warning" onClick={() => { handleApprovePlan(detailCode, 2); }}>Approve</button>)
                                                    ) :
                                                        detailStatus == 'Not Approved' && detailCode !== -1
                                                            ? (<button className="btn btn-warning" onClick={() => { handleApprovePlan(detailCode, 1); }}>Send Request</button>)
                                                            : detailStatus === 'Approved' && detailCode !== -1 ? (
                                                                detailStatus
                                                            ) : ""
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        {listUpdatesPlanning.length > 1 ? (
                                            <div className="col">
                                                <div className="col p-0 m-0 ">
                                                    <label className="font-weight-bold">

                                                    </label>
                                                </div>

                                                <div className="col p-0 m-0">
                                                    <label id="user-fullname" name="subject" className="rounded-0" >
                                                        <button className="btn btn-warning" onClick={() => { updateDetails(null, null, null, "all"); setRefreshTab(true) }}>Apply all changes</button>
                                                    </label>
                                                </div>
                                            </div>
                                        ) : (<></>)}

                                        {listUpdatesDB.length > 1 && approvedRequest === 1 ? (
                                            <div className="col">
                                                <div className="col p-0 m-0 ">
                                                    <label className="font-weight-bold">
                                                        {/* <button className="btn btn-danger" onClick={() => { validateChanges("decline"); setRefreshTab(true) }}>Decline All changes</button> */}
                                                    </label>
                                                </div>

                                                <div className="col p-0 m-0">
                                                    <label id="user-fullname" name="subject" className="rounded-0" >
                                                        <button className="btn btn-danger" onClick={() => { validateChanges("decline"); setRefreshTab(true) }}>Decline All changes</button>
                                                    </label>
                                                </div>
                                            </div>
                                        ) : (<></>)}

                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="h-full pb-2 overflow-auto mt-10">


                            <div className="col">
                            </div>
                            <table id="customers max-h-300px" className="table ">
                                <thead className="position-fixe">
                                    <tr>
                                        <th scope="col" className="w-25px"></th>
                                        <th scope="col" className="w-25px">
                                            #
                                        </th>

                                        <th scope="col" className="text-left" style={{ width: '2px' }} onClick={() => { }}>
                                            Nom du client
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketidModal"
                                            >
                                                <i className="la la-filter" onClick={() => { }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Region
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketidModal"
                                            >
                                                <i className="la la-filter" onClick={() => { }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Adresse
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Date prevue
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Nom du contact
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { }}></i>
                                            </a>
                                        </th>
                                        <th scope="col" className="text-left">
                                            Email du contact
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { }}></i>
                                            </a>
                                        </th>
                                        {/* <th scope="col" className="text-left">
                                            Status
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { }}></i>
                                            </a>
                                        </th> */}
                                        <th scope="col" className="text-left" >
                                            Date limite
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { }}></i>
                                            </a>
                                        </th>
                                        <th scope="col" className="text-left" style={{ width: '500px' }}>
                                            But de la visite
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { }}></i>
                                            </a>
                                        </th>
                                        {detailStatus != "Not Approved" && detailStatus != "Pending" ? (
                                            <>
                                                <th scope="col" className="text-left">
                                                    Agent commercial
                                                    <a
                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#priorityModal"
                                                    >
                                                        <i className="la la-filter" onClick={() => { }}></i>
                                                    </a>
                                                </th>
                                                <th scope="col" className="text-left">
                                                    Remarques
                                                    <a
                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#priorityModal"
                                                    >
                                                        <i className="la la-filter" onClick={() => { }}></i>
                                                    </a>
                                                </th>
                                                <th scope="col" className="text-left">
                                                    Status
                                                    <a
                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#priorityModal"
                                                    >
                                                        <i className="la la-filter" onClick={() => { }}></i>
                                                    </a>
                                                </th>
                                                <th scope="col" className="text-left">
                                                    Actions a suivre
                                                    <a
                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#priorityModal"
                                                    >
                                                        <i className="la la-filter" onClick={() => { }}></i>
                                                    </a>
                                                </th>
                                                <th scope="col" className="text-left">
                                                    Priorite de l'action
                                                    <a
                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#priorityModal"
                                                    >
                                                        <i className="la la-filter" onClick={() => { }}></i>
                                                    </a>
                                                </th>
                                                <th scope="col" className="text-left">
                                                    Persone en Charge
                                                    <a
                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#priorityModal"
                                                    >
                                                        <i className="la la-filter" onClick={() => { }}></i>
                                                    </a>
                                                </th>
                                                <th scope="col" className="text-left">
                                                    Resultat de l'action
                                                    <a
                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#priorityModal"
                                                    >
                                                        <i className="la la-filter" onClick={() => { }}></i>
                                                    </a>
                                                </th>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </tr>
                                </thead>
                                <tbody id="resultdata">
                                    {
                                        currentPlanning ? (
                                            currentPlanning.map((d, index) => (
                                                <tr
                                                    key={index}
                                                    className={d.plan_deleted === "pending" ? "text-info border border-danger disabled" : "text-info"}
                                                    onClick={(e) => {
                                                        if (d.plan_deleted === "pending" && approvedRequest === 1) {
                                                            setDetailForDelete(d.detail_code);
                                                            SetModalConfirmDelete(true);
                                                        }
                                                    }}
                                                >
                                                    <Dropdown className={"dropdown"}>
                                                        <Dropdown.Toggle
                                                            variant="dropdown-basic"
                                                            className="w-60px py-0 text-white p-0 m-0"
                                                        >
                                                            <div
                                                                className="dropdown dropdown-inline bg-light2 py-0 rounded"
                                                                data-toggle="dropdown"

                                                            >
                                                                <a

                                                                    type="button"
                                                                    className="btn btn-light2 btn-icon py-0 btn-sm"
                                                                >
                                                                    <i className="ki ki-bold-more-hor"></i>
                                                                </a>
                                                            </div>
                                                        </Dropdown.Toggle>

                                                        {detailStatus !== "Pending" ? (
                                                            <Dropdown.Menu className="">
                                                                {d.plan_deleted !== "pending" ? (
                                                                    <>
                                                                        {detailStatus !== 'Not Approved' ? (
                                                                            <>
                                                                                <Dropdown.Item
                                                                                    href="#"
                                                                                    className="dropdown-item border-bottom border-top"
                                                                                    onClick={() => { setCust(d.cust_code); setModalUploadReport(true); }}
                                                                                >
                                                                                    <i className="fa fa-upload"></i>{" "}
                                                                                    <span className="pl-3">Upload report</span>
                                                                                </Dropdown.Item>

                                                                                <Dropdown.Item
                                                                                    href="#"
                                                                                    className="dropdown-item border-bottom border-top"
                                                                                    onClick={() => { setCustForUpload(d.cust_code); SetModalReports(true); seeAllReport(d.cust_code); }}
                                                                                >
                                                                                    <i className="far fa-file"></i>{" "}
                                                                                    <span className="pl-3">Reports</span>
                                                                                </Dropdown.Item>
                                                                            </>
                                                                        ) : (<></>)}
                                                                        <Dropdown.Item
                                                                            href="#"
                                                                            className="dropdown-item border-bottom border-top"
                                                                            onClick={() => { setCustForUpload(d.cust_code); setDetailForDelete(d.detail_code); SetModalSendRequestDelete(true) }}
                                                                        >
                                                                            <i className="fa fa-trash"></i>{" "}
                                                                            <span className="pl-3">Delete Planning</span>
                                                                        </Dropdown.Item>
                                                                    </>
                                                                ) : ("")}
                                                            </Dropdown.Menu>
                                                        ) : (<></>)}


                                                    </Dropdown>

                                                    <td>
                                                        {d.detail_code}
                                                    </td>

                                                    <td className="text-left">
                                                        <input type="text" style={{ border: "none" }} defaultValue={d.cust_name} readOnly size={12} disabled={d.plan_deleted === "pending" ? true : false} />
                                                    </td>

                                                    <td id="tb-assigned-to" className="text-left">
                                                        <input type="text" style={{ border: "none" }} defaultValue={d.reg_name} readOnly size={10} disabled={d.plan_deleted === "pending" ? true : false} />
                                                    </td>

                                                    <td id="tb-created-date" className="text-left">
                                                        <input type="text" style={{ border: "none" }} defaultValue={d.cust_address} readOnly size={10} disabled={d.plan_deleted === "pending" ? true : false} />
                                                    </td>

                                                    <td id="tb-created-date" className="text-left">
                                                        <div className="container text-left">
                                                            <div className="row text-left">
                                                                <div className="col">
                                                                    <input type="date" onDoubleClick={() => checkUpdates(d.detail_code, "dateVisite")} className={d.plan_date_visite ? (d.plan_date_visite[d.plan_date_visite.length - 1] === "*" ? "border border-danger" : "") : ""} style={{ border: "none" }} value={d.plan_date_visite ? (d.plan_date_visite[d.plan_date_visite.length - 1] === "*" ? d.plan_date_visite.substring(0, d.plan_date_visite.length - 1) : d.plan_date_visite) : ""} onChange={(e) => { changeValue(d.detail_code, "dateVisite", e.target.value, index + (linePerPage * (currentPage - 1))) }} size="10" disabled={d.plan_deleted === "pending" || detailStatus === "Pending" ? true : false} />
                                                                </div>
                                                                {listDetailPlanning[index + (linePerPage * (currentPage - 1))].plan_date_visite ? (
                                                                    listDetailPlanning[index + (linePerPage * (currentPage - 1))].plan_date_visite !== listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_date_visite && listDetailPlanning[index + (linePerPage * (currentPage - 1))].plan_date_visite !== listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_date_visite + "*" ? (
                                                                        <div className="col-md-2 cursor-pointer" onClick={() => { updateDetails(d.plan_date_visite, "dateVisite", d.detail_code, '', listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_date_visite) }}>
                                                                            <i className="fa fa-paper-plane" ></i>
                                                                        </div>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                ) : (<> </>)}
                                                            </div>
                                                        </div>

                                                    </td>

                                                    <td id="tb-created-date" className="text-left">
                                                        <select className="cursor-pointer " style={{ border: "none" }} onClick={() => { getPersons(d.cust_code, d.contact_code); setActCust(d.cust_code) }} onChange={(e) => { beforeToBlur(e.target.value, "Contact", d.detail_code) }} onBlur={() => { blur(d.contact_code) }} disabled={d.plan_deleted === "pending" || detailStatus === "Pending" ? true : false}>
                                                            <option value={d.contact_code} key={0}>{d.contact_name}</option>
                                                            {listPersonOfContact ? listPersonOfContact.map((f, index) => (
                                                                <option key={index} value={f.contact_code}>{f.contact_name}</option>
                                                            )) : (
                                                                <></>
                                                            )}
                                                        </select>
                                                    </td>

                                                    <td id="tb-created-date" className="text-left">
                                                        <label className="text-black">
                                                            {d.contact_email}
                                                        </label>

                                                    </td>

                                                    <td id="tb-created-date" className="text-left">
                                                        <div className="container text-left">
                                                            <div className="row text-left">
                                                                <div className="col">
                                                                    <input type="date" onDoubleClick={() => checkUpdates(d.detail_code, "dateLimite")} className={d.plan_date_limite != null ? (d.plan_date_limite[d.plan_date_limite.length - 1] === "*" ? "border border-danger" : "") : ""} style={{ border: "none" }} value={d.plan_date_limite != null ? (d.plan_date_limite[d.plan_date_limite.length - 1] === "*" ? d.plan_date_limite.substring(0, d.plan_date_limite.length - 1) : d.plan_date_limite) : ""} onChange={(e) => { changeValue(d.detail_code, "dateLimite", e.target.value, index + (linePerPage * (currentPage - 1))) }} size="10" disabled={d.plan_deleted === "pending" || detailStatus === "Pending" ? true : false} />
                                                                </div>

                                                                {listDetailPlanning[index + (linePerPage * (currentPage - 1))].plan_date_limite ? (
                                                                    listDetailPlanning[index + (linePerPage * (currentPage - 1))].plan_date_limite !== listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_date_limite && listDetailPlanning[index + (linePerPage * (currentPage - 1))].plan_date_limite !== listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_date_limite + "*" ? (
                                                                        <div className="col-md-2 cursor-pointer" onClick={() => { updateDetails(d.plan_date_limite, "dateLimite", d.detail_code, '', listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_date_limite) }}>
                                                                            <i className="fa fa-paper-plane" ></i>
                                                                        </div>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                ) : (<> </>)}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td id="tb-created-date" className="text-left">
                                                        <div className="container text-left">
                                                            <div className="row text-left">
                                                                <div className="col">
                                                                    <input type="text" onDoubleClick={() => {
                                                                        checkUpdates(d.detail_code, "ButVisite")
                                                                    }} className={d.plan_but_visite != null ? (d.plan_but_visite[d.plan_but_visite.length - 1] === "*" ? "border border-danger" : "") : ""} style={{ border: "none" }} value={d.plan_but_visite != null ? (d.plan_but_visite[d.plan_but_visite.length - 1] === "*" ? d.plan_but_visite.substring(0, d.plan_but_visite.length - 1) : d.plan_but_visite) : ""} onChange={(e) => { /*beforeToBlur(e.target.value, "ButVisite", d.detail_code);*/ changeValue(d.detail_code, "ButVisite", e.target.value, index + (linePerPage * (currentPage - 1))) }} /*onBlur={() => { blur(d.plan_but_visite) }}*/ size="10" disabled={d.plan_deleted === "pending" || detailStatus === "Pending" ? true : false} />
                                                                </div>

                                                                {detailStatus === "Pending" ? (<></>) : (
                                                                    listDetailPlanning[index + (linePerPage * (currentPage - 1))].plan_but_visite ? (
                                                                        listDetailPlanning[index + (linePerPage * (currentPage - 1))].plan_but_visite !== listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_but_visite && listDetailPlanning[index + (linePerPage * (currentPage - 1))].plan_but_visite !== listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_but_visite + "*" ? (
                                                                            <div className="col-md-2 cursor-pointer" onClick={() => { updateDetails(d.plan_but_visite, "ButVisite", d.detail_code, '', listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_but_visite) }}>
                                                                                <i className="fa fa-paper-plane" ></i>
                                                                            </div>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    ) : (<> </>)
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {detailStatus === "Approved" ? (
                                                        <>
                                                            <td id="tb-created-date" className="text-left">
                                                                <div className="container text-left">
                                                                    <div className="row text-left">
                                                                        <div className="col">
                                                                            <input onDoubleClick={() => {
                                                                                checkUpdates(d.detail_code, "AgentCharge")
                                                                            }} className={d.plan_agent_charge != null ? (d.plan_agent_charge[d.plan_agent_charge.length - 1] === "*" ? "border border-danger" : "") : ""} type="text" style={{ border: "none" }} value={d.plan_agent_charge != null ? (d.plan_agent_charge[d.plan_agent_charge.length - 1] === "*" ? d.plan_agent_charge.substring(0, d.plan_agent_charge.length - 1) : d.plan_agent_charge) : ""} onChange={(e) => { changeValue(d.detail_code, "AgentCharge", e.target.value, index + (linePerPage * (currentPage - 1))) /*beforeToBlur(e.target.value, "AgentCharge", d.detail_code)*/ }} onBlur={() => { /*blur(d.plan_agent_charge)*/ }} size="14" disabled={d.plan_deleted === "pending" ? true : false} />
                                                                        </div>

                                                                        {listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_agent_charge !== d.plan_agent_charge && listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_agent_charge + "*" !== d.plan_agent_charge ? (
                                                                            <div className="col-md-2 ml-1">
                                                                                <i className="fa fa-paper-plane cursor-pointer" onClick={() => { updateDetails(d.plan_agent_charge, "AgentCharge", d.detail_code, '', listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_agent_charge) }} size="2"></i>
                                                                            </div>
                                                                        ) : (<></>)}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td id="tb-created-date" className={"text-left "} >
                                                                <div className="container text-left">
                                                                    <div className="row text-left">
                                                                        <div className="col">
                                                                            <input onDoubleClick={() => { checkUpdates(d.detail_code, "Remarque") }} className={d.plan_remarque != null ? (d.plan_remarque[d.plan_remarque.length - 1] === "*" ? "border border-danger" : "") : ""} type="text" style={{ border: "none" }} value={d.plan_remarque != null ? (d.plan_remarque[d.plan_remarque.length - 1] === "*" ? d.plan_remarque.substring(0, d.plan_remarque.length - 1) : d.plan_remarque) : ""} onChange={(e) => { changeValue(d.detail_code, "Remarque", e.target.value, index + (linePerPage * (currentPage - 1))) /*beforeToBlur(e.target.value, "Remarque", d.detail_code)*/ }} onBlur={() => { /*blur(d.plan_remarque)*/ }} size="10" disabled={d.plan_deleted === "pending" ? true : false} />
                                                                        </div>
                                                                        {listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_remarque !== d.plan_remarque && listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_remarque + "*" !== d.plan_remarque ? (
                                                                            <div className="col-md-2 cursor-pointer" onClick={() => { updateDetails(d.plan_remarque, "Remarque", d.detail_code, '', listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_remarque) }}>
                                                                                <i className="fa fa-paper-plane" size="2"></i>
                                                                            </div>
                                                                        ) : (<></>)}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className={d.plan_status !== d.plan_status_2 + "" ? "border-danger dropdown-item border-bottom border-top" : ""}>
                                                                <label onClick={() => {
                                                                    if (d.plan_status === d.plan_status_2 + "") {
                                                                        setModalChangeStatus(true); setCodeStatus(d.detail_code); setStatusCode(d.detStatus_code); setStatusName(d.detStatus_name)
                                                                    } else {
                                                                        checkUpdates(d.detail_code, "Status")
                                                                    }

                                                                }}>{d.detStatus_name}</label>

                                                            </td>
                                                            <td id="tb-created-date" >
                                                                <input type="text" style={{ border: "none" }} defaultValue={d.nbreAction + " Action"} onClick={(e) => { setCust_edited(d.cust_name); setDetCodeEdited(d.detail_code); /*beforeToBlur(e.target.value, "action", d.detail_code);*/ getListPlanAndCustomer(d.detail_code); setModalAddActionSuivre(true) }} disabled={d.plan_deleted === "pending" ? true : false} />
                                                            </td>

                                                            <td id="tb-created-date" className="text-left">
                                                                <div className="container text-left">
                                                                    <div className="row text-left">
                                                                        <div className="col">
                                                                            <input onDoubleClick={() => {
                                                                                checkUpdates(d.detail_code, "Priorite")
                                                                            }
                                                                            } className={d.plan_priorite_action != null ? (d.plan_priorite_action[d.plan_priorite_action.length - 1] === "*" ? "border border-danger" : "") : ""} type="text" style={{ border: "none" }} value={d.plan_priorite_action != null ? (d.plan_priorite_action[d.plan_priorite_action.length - 1] === "*" ? d.plan_priorite_action.substring(0, d.plan_priorite_action.length - 1) : d.plan_priorite_action) : ""} onChange={(e) => { changeValue(d.detail_code, "Priorite", e.target.value, index + (linePerPage * (currentPage - 1))) /*beforeToBlur(e.target.value, "Priorite", d.detail_code)*/ }} onBlur={() => { /*blur(d.plan_priorite_action) */ }} size="13" disabled={d.plan_deleted === "pending" ? true : false} />
                                                                        </div>
                                                                        {listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_priorite_action !== d.plan_priorite_action && listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_priorite_action + "*" !== d.plan_priorite_action ? (
                                                                            <div className="col-md-2 cursor-pointer" onClick={() => { updateDetails(d.plan_priorite_action, "Priorite", d.detail_code, '', listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_priorite_action) }}>
                                                                                <i className="fa fa-paper-plane" size="2"></i>
                                                                            </div>
                                                                        ) : (<></>)}
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td id="tb-created-date" className="text-left">
                                                                <div className="container text-left">
                                                                    <div className="row text-left">
                                                                        <div className="col">
                                                                            <input onDoubleClick={() => { checkUpdates(d.detail_code, "PersonneCharge") }} className={d.plan_personne_charge != null ? (d.plan_personne_charge[d.plan_personne_charge.length - 1] === "*" ? "border border-danger" : "") : ""} type="text" style={{ border: "none" }} value={d.plan_personne_charge != null ? (d.plan_personne_charge[d.plan_personne_charge.length - 1] === "*" ? d.plan_personne_charge.substring(0, d.plan_personne_charge.length - 1) : d.plan_personne_charge) : ""} onChange={(e) => { changeValue(d.detail_code, "PersonneCharge", e.target.value, index + (linePerPage * (currentPage - 1))) /*beforeToBlur(e.target.value, "PersonneCharge", d.detail_code)*/ }} onBlur={() => { /*blur(d.plan_personne_charge)*/ }} size="13" disabled={d.plan_deleted === "pending" ? true : false} />
                                                                        </div>
                                                                        {listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_personne_charge !== d.plan_personne_charge && listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_personne_charge + "*" !== d.plan_personne_charge ? (
                                                                            <div className="col-md-2 cursor-pointer" onClick={() => { updateDetails(d.plan_personne_charge, "PersonneCharge", d.detail_code, '', listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_personne_charge) }}>
                                                                                <i className="fa fa-paper-plane" size="2"></i>
                                                                            </div>
                                                                        ) : (<></>)}
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td id="tb-created-date" className="text-left">
                                                                <div className="container text-left">
                                                                    <div className="row text-left">
                                                                        <div className="col">
                                                                            <input onDoubleClick={() => { checkUpdates(d.detail_code, "Resultat") }} className={d.plan_resultat_action != null ? (d.plan_resultat_action[d.plan_resultat_action.length - 1] === "*" ? "border border-danger" : "") : ""} type="text" style={{ border: "none" }} value={d.plan_resultat_action != null ? (d.plan_resultat_action[d.plan_resultat_action.length - 1] === "*" ? d.plan_resultat_action.substring(0, d.plan_resultat_action.length - 1) : d.plan_resultat_action) : ""} onChange={(e) => { changeValue(d.detail_code, "Resultat", e.target.value, index + (linePerPage * (currentPage - 1))) /*beforeToBlur(e.target.value, "Resultat", d.detail_code)*/ }} onBlur={() => {/*blur(d.plan_resultat_action)*/ }} size="13" disabled={d.plan_deleted === "pending" ? true : false} />
                                                                        </div>
                                                                        {listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_resultat_action !== d.plan_resultat_action && listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_resultat_action + "*" !== d.plan_resultat_action ? (
                                                                            <div className="col-md-1 cursor-pointer" onClick={() => { updateDetails(d.plan_resultat_action, "Resultat", d.detail_code, '', listDetailPlanning2[index + (linePerPage * (currentPage - 1))].plan_resultat_action) }}>
                                                                                <i className="fa fa-paper-plane" size="1"></i>
                                                                            </div>
                                                                        ) : (<></>)}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <>

                                                        </>
                                                    )}

                                                </tr>
                                            ))

                                        ) : (
                                            <tr>
                                                <td colSpan={11} className="text-center pt-20 fs-16">
                                                    No data found
                                                </td>
                                            </tr>
                                        )
                                    }


                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer pt-2 pb-2 col-8 mb-0 bg-white">
                        <div className="d-flex justify-content-center">
                            <Pagination
                                postsPerPage={linePerPage}
                                totalPosts={listDetailPlanning.length}
                                paginate={paginate}
                            />
                        </div>
                    </div>
                </div>

            </div>

            <Footer />
        </>
    );
}



export default Planning;