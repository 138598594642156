import logo from "../Assets/img/auto-logo.jpg";
import Dropdown from "react-bootstrap/Dropdown";
//import Language from "./language";
import React, { useEffect, useState } from "react";
//import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import { ReactSession } from "react-client-session";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { MDBCheckbox } from "mdb-react-ui-kit";
var bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);
//

function Header() {
  const url = process.env.REACT_APP_URL;
  const headers = JSON.parse(process.env.REACT_APP_CONFIG);
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [oldpassword, setOldpassword] = useState();
  const [newpassword, setNewpassword] = useState();
  const [confirmpassword, setConfirmpassword] = useState();
  const [showChangePassword, setShowChangePassword] = useState(false);

  const [Messageoldpassword, setMessageOldpassword] = useState();
  const [Messagenewpassword, setMessageNewpassword] = useState();
  const [vuepassword, setVuepassword] = useState(false);
  const [Messageconfirmpassword, setMessageConfirmpassword] = useState();


  const [customerOption, setCustomerOption] = useState(0);
  const [userOption, setUserOption] = useState(0);
  const [personOfCOntactOption, setPersonOfCOntactOption] = useState(0);
  const [viewLogs, setViewLogs] = useState(0);


  const handleLogout = () => {
    ReactSession.remove("sales_id");
    ReactSession.remove("sales_username");
    ReactSession.remove("sales_mail");

    localStorage.clear();
    navigate("/");
  };

  const check_verif = () => {
    if (vuepassword === false) {
      setVuepassword(true);
    } else {
      setVuepassword(false);
    }
  };

  useEffect(() => {
    getUserPermissions();
  }, []);

  const change_password = () => {
    let verif = 0;
    if (oldpassword === "") {
      verif = 1;
      setMessageOldpassword("Old password is empty");
    }
    if (newpassword === "") {
      setMessageNewpassword("New password is empty");
      verif = 1;
    }
    if (confirmpassword === "") {
      verif = 1;
      setMessageConfirmpassword("You must confirm the new password");
    }

    if (verif === 0) {
      if (newpassword === confirmpassword) {
        console.log('ccc');
        var hash = bcrypt.hashSync(newpassword, salt);
        axios.post(url + "?p=resetPassword", {
          author: ReactSession.get('sales_id'),
          username: ReactSession.get("sales_username"),
          password: hash,
          id: ReactSession.get("sales_id"),
        }, headers).then((response) => {
          if (response.data.status === true) {
            notifySuccess("Password has been changed");
            setShowChangePassword(false);
          }
        })
      } else {
        setMessageConfirmpassword(
          "The first password and the second password do not match"
        );
      }
    }
  };

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const handleModelClosechangePassword = () => {
    setShowChangePassword(false);
    setOldpassword("");
    setNewpassword("");
    setConfirmpassword("");
    setMessageOldpassword("");
    setMessageNewpassword("");
    setMessageConfirmpassword("");
  };

  const getUserPermissions = () => {
    axios.post(url + "?p=getUserPermissions", {
      plan_author: ReactSession.get("sales_id"),
    }, headers).then((response) => {
      if (response.data.status) {
        response.data['result'].map((e) => {
          switch (e.userPerm_code) {
            case 402:
              setCustomerOption(e.userPerm_value);
              break;
            case 403:

              setUserOption(e.userPerm_value);

              break;
            case 404:

              setPersonOfCOntactOption(e.userPerm_value);

              break;
            case 417:

              setViewLogs(e.userPerm_value);

              break;
            default:
              break;

          }
        })
      } else {

      }
    })
  }



  return (
    <>
      <div
        id="kt_header"
        className={"header header-fixed bg-white text-dark d-print-none d-lg-flex d-none h-60px"}
      >

        <Modal
          show={showChangePassword}
          onHide={handleModelClosechangePassword}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Change password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="form-group col-xl-12 ">
                <label className="font-weight-bold">
                  Old password<span className="text-danger pl-3">*</span>
                </label>
                <input
                  id="old-password"
                  type={vuepassword ? "text" : "password"}
                  onChange={(e) => {
                    setOldpassword(e.target.value);
                  }}
                  name="subject"
                  className="form-control rounded-0"
                />
                <div id="cstm-code-error" className="text-danger pt-2">
                  {Messageoldpassword}
                </div>
              </div>

              <div className="form-group col-xl-12 ">
                <label className="font-weight-bold">
                  New password<span className="text-danger pl-3">*</span>
                </label>
                <input
                  id="new-password"
                  type={vuepassword ? "text" : "password"}
                  onChange={(e) => {
                    setNewpassword(e.target.value);
                  }}
                  name="subject"
                  className="form-control rounded-0"
                />
                <div id="cstm-code-error" className="text-danger pt-2">
                  {Messagenewpassword}
                </div>
              </div>

              <div className="form-group col-xl-12 ">
                <label className="font-weight-bold">
                  Confirm password<span className="text-danger pl-3">*</span>
                </label>
                <input
                  id="confirm-password"
                  type={vuepassword ? "text" : "password"}
                  onChange={(e) => {
                    setConfirmpassword(e.target.value);
                  }}
                  name="subject"
                  className="form-control rounded-0"
                />
                <div id="cstm-code-error" className="text-danger pt-2">
                  {Messageconfirmpassword}
                </div>
              </div>
              <MDBCheckbox
                className="ml-30"
                name="flexCheck"
                onChange={check_verif}
                value={vuepassword}
                id="shwpssw"
                label="Show password"
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModelClosechangePassword}>
              Cancel
            </Button>
            <Button variant="primary" onClick={change_password}>
              Change password
            </Button>
          </Modal.Footer>
        </Modal>

        <Toaster position="top-right" reverseOrder={false} />
        <div className="container  d-flex align-items-stretch justify-content-between py-3">
          <div className="d-flex align-items-stretch mr-3">
            <div className="header-logo">
              <a href={"/home"}>
                <img
                  alt="Logo"
                  src={logo}
                  className="logo-default max-h-40px"
                />
              </a>
            </div>

            <div
              className="header-menu-wrapper header-menu-wrapper-left"
              id="kt_header_menu_wrapper"
            >
              <div
                id="kt_header_menu"
                className="header-menu header-menu-left header-menu-mobile  header-menu-layout-default "
              >
                <ul className="menu-nav">
                  <li className="menu-item menu-item-rel">
                    <a href="/Home" className="menu-link">
                      <span className="text-dark-75 font-weight-bolder">
                        Home
                      </span>
                      <i className="menu-arrow pb-2"></i>
                    </a>
                  </li>
                  <li className="menu-item menu-item-rel">
                    <a href="/Planning" className="menu-link">
                      <span className="text-dark-75 font-weight-bolder">
                        Planning
                      </span>
                      <i className="menu-arrow pb-2"></i>
                    </a>
                  </li>
                  {customerOption === 0 && userOption === 0 && personOfCOntactOption === 0 ? (
                    <></>
                  ) : (
                    <li className="menu-item menu-item-rel">
                      <div className="menu-link">
                        <Dropdown>

                          <Dropdown.Toggle
                            variant="dropdown-basic"
                            className="h-40px pt-0 pb-0 "
                          >
                            <span className="text-black text-dark-75 opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
                              Settings
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="w-100px">
                            {customerOption === 1 ? (
                              <Dropdown.Item onClick={() => navigate(("/customers"))}>
                                <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                  Customers
                                </div>
                              </Dropdown.Item>
                            ) : (<></>)}
                            {userOption === 1 ? (
                              <Dropdown.Item onClick={() => navigate(("/users"))}>
                                <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                  Users
                                </div>
                              </Dropdown.Item>
                            ) : (<></>)}

                            {personOfCOntactOption === 1 ? (
                              <Dropdown.Item onClick={() => navigate(("/Pcontact"))}>
                                <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                  Person of contact
                                </div>
                              </Dropdown.Item>
                            ) : (<></>)}

                            {viewLogs === 1 ? (
                              <Dropdown.Item onClick={() => navigate(("/Logs"))}>
                                <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                  Logs
                                </div>
                              </Dropdown.Item>
                            ) : (<></>)}

                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </li>
                  )}


                </ul>
              </div>
            </div>
          </div>

          <div className="topbar">

            <div className="dropdown mr-0 pt-0">
              <div className="menu-link">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="dropdown-basic"
                    className="h-40px pt-0 pb-0 "
                  >
                    Welcome,
                    <span className="text-black text-dark-75 opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
                      {/* {cookies.username.substring(0, 2).toUpperCase()} */}
                      {ReactSession.get("sales_username").substring(0, 2).toUpperCase()}
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="w-250px">
                    <Dropdown.Item onClick={() => setShowChangePassword(true)}>
                      <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                        Change password
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleLogout()}>
                      <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                        Logout
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
