import axios from "axios";
import { useEffect, useState } from "react";
import "../Assets/css/bootstrap.min.css";
import Header from "./header";
import Header_mobile from "./header_mobile";
import Footer from "./footer";
import { ReactSession } from "react-client-session";
import { useAsyncError, useNavigate } from "react-router-dom";
import { BiRefresh } from "react-icons/bi";
import Accordion from 'react-bootstrap/Accordion';

import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import toast, { Toaster } from "react-hot-toast";
import { FcMinus, FcPlus } from 'react-icons/fc';
var bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);

function Users() {
    const url = process.env.REACT_APP_URL;
    const headers = JSON.parse(process.env.REACT_APP_CONFIG);
    const navigate = useNavigate()
    const [modalAddUser, setModalAddUser] = useState(false);
    const [modalEditUser, setModalEditUser] = useState(false);
    const [modalNewPassword, setModalNewPassword] = useState(false);
    const [modalFilter, setModalFilter] = useState(false);
    const [modalConfirmChangeStatus, setModalConfirmChangeStatus] = useState(false);


    const [choosenAdd, setChoosenAdd] = useState(0);



    const [listUser, setListUser] = useState([]);
    const [listUser1, setListUser1] = useState([]);


    const [message_userName, setMessageUsername] = useState("");
    const [message_fullName, setMessageUserFullName] = useState("");
    const [messagePassword, setMessageUserPassword] = useState("");


    const [refresh, setRefresh] = useState(false);
    const [userFullName, setFullName] = useState("");
    const [userName, setUserName] = useState("");
    const [userEmail, setEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");

    const [idUpdate, setIdUpdate] = useState(0);
    const [fullnameUpdate, setFullNameUpdate] = useState("");
    const [usernameUpdate, setUserNameUpdate] = useState("");
    const [emailUpdate, setEmailUpdate] = useState("");
    const [newUserPassword, setNewUserPassword] = useState("");

    const [filterType, setFilterType] = useState("");
    const [modalFilterText, setModalFilterText] = useState("");

    const [permAdm, setPermAdm] = useState(0);
    const [permDir, setPermDir] = useState(0);



    const [currentPage, setCurrentPage] = useState(1);
    const [linePerPage, setlinePerPage] = useState(11);
    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const currentPosts = listUser.slice(indexOfFirstPost, indexOfLastPost);



    const [addPlaning, setAddPlanning] = useState(0);
    const [approvedRequest, setApprovedRequest] = useState(0);
    const [customerOption, setCustomerOption] = useState(0);
    const [userOption, setUserOption] = useState(0);
    const [personOfCOntactOption, setPersonOfCOntactOption] = useState(0);
    const [addCustomer, setAddCustomer] = useState(0);
    const [addTypeOfBusiness, setAddTypeOfBusiness] = useState(0);
    const [addRegion, setAddRegion] = useState(0);
    const [editCustomerP, setEditCustomerP] = useState(0);
    const [addPersonOfContactToUser, setAddPersonOfContactToUser] = useState(0);
    const [listPersonOfContactForUser, setListPersonOfContactForUser] = useState(0);
    const [addPerson, setAddPerson] = useState(0);
    const [editPerson, setEditPerson] = useState(0);
    const [addUserP, setAddUserP] = useState(0);
    const [editUserP, setEditUserP] = useState(0);
    const [resetPasswordP, setResetPasswordP] = useState(0);
    const [disabledUser, setDisabledUser] = useState(0);

    const [viewLogs, setViewLogs] = useState(0)


    const [addUserBtn, setAddUserBtn] = useState(0);
    const [editUserBtn, setEditUserBtn] = useState(0);
    const [resetPasswordBtn, setResetPasswordBtn] = useState(0);
    const [disabledUserBtn, setDisabledUserBtn] = useState(0);





    useEffect(() => {
        getUserPermissions();
        getListUser();
    }, [refresh]);


    const getListUser = () => {
        axios.post(url + "?p=getListUser", {
        }, headers).then((response) => {
            if (response.data.status) {
                setListUser(response.data.list);
                setListUser1(response.data.list);
            }
        })
    }

    function getUserInfo(id) {
        axios.post(url + "?p=getUserInfo", {
            userId: id
        }, headers).then((response) => {
            if (response.data.type) {
                setIdUpdate(id);
                setFullNameUpdate(response.data.user_fullname);
                setUserNameUpdate(response.data.user_name);
                setEmailUpdate(response.data.user_email);

                var i = response.data.results.length;
                var x = 0;
                while (x < i) {
                    switch (response.data.results[x].userPerm_code) {
                        case 400:
                            setAddPlanning(response.data.results[x].userPerm_value);
                            break;
                        case 401:
                            setApprovedRequest(response.data.results[x].userPerm_value);
                            break;
                        case 402:
                            setCustomerOption(response.data.results[x].userPerm_value);
                            break;
                        case 403:

                            setUserOption(response.data.results[x].userPerm_value);

                            break;
                        case 404:

                            setPersonOfCOntactOption(response.data.results[x].userPerm_value);

                            break;
                        case 405:

                            setAddCustomer(response.data.results[x].userPerm_value);

                            break;
                        case 406:

                            setAddTypeOfBusiness(response.data.results[x].userPerm_value);

                            break;
                        case 407:

                            setAddRegion(response.data.results[x].userPerm_value);

                            break;
                        case 408:

                            setEditCustomerP(response.data.results[x].userPerm_value);

                            break;
                        case 409:

                            setAddPersonOfContactToUser(response.data.results[x].userPerm_value);

                            break;
                        case 410:

                            setListPersonOfContactForUser(response.data.results[x].userPerm_value);

                            break;
                        case 411:

                            setAddPerson(response.data.results[x].userPerm_value);

                            break;
                        case 412:

                            setEditPerson(response.data.results[x].userPerm_value);

                            break;
                        case 413:

                            setAddUserP(response.data.results[x].userPerm_value);

                            break;
                        case 414:

                            setEditUserP(response.data.results[x].userPerm_value);

                            break;
                        case 415:

                            setResetPasswordP(response.data.results[x].userPerm_value);

                            break;
                        case 416:

                            setDisabledUser(response.data.results[x].userPerm_value);

                            break;
                        case 417:

                            setViewLogs(response.data.results[x].userPerm_value);

                            break;
                        default:
                            break;

                    }
                    x++;
                }
            }
        });
    }


    function addUsers() {
        var t = true;
        if (userName === "") { setMessageUsername("Complete your userName"); t = false }
        else { setMessageUsername(""); }
        if (userFullName === "") { setMessageUserFullName("Complete your full name"); t = false }
        else setMessageUserFullName("");
        if (userPassword === "") { setMessageUserPassword("Complete your password"); t = false }
        else setMessageUserPassword("");
        if (t) {
            var hash = bcrypt.hashSync(userPassword, salt);
            axios.post(url + "?p=addUsers", {
                author: ReactSession.get('sales_id'),
                userName: userName,
                userFullName: userFullName,
                userEmail: userEmail,
                userPassword: hash,
                addPlaning,
                approvedRequest,
                customerOption,
                userOption,
                personOfCOntactOption,
                addCustomer,
                addTypeOfBusiness,
                addRegion,
                editCustomerP,
                addPersonOfContactToUser,
                listPersonOfContactForUser,
                addPerson,
                editPerson,
                addUserP,
                editUserP,
                resetPasswordP,
                disabledUser,
                viewLogs
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("The user has been added succesfully")
                    handleTypeClose();
                } else {
                    notifyError("Error when we add the new user");
                }
            });
        } else {
            setChoosenAdd(0);
        }
    }

    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const resetPassword = () => {
        var password = makeid(5);
        var hash = bcrypt.hashSync(password, salt);
        console.log(userCode);
        axios.post(url + "?p=resetPassword", {
            author: ReactSession.get('sales_id'),
            id: userCode,
            password: hash
        }, headers).then((response) => {
            if (response.data.status) {
                setNewUserPassword(password);
                setModalNewPassword(true);
            } else {
                notifyError("error when we reset the password");
            }
        })
    }

    const updateStatus = (id, stat) => {
        axios.post(url + "?p=updateStatus", {
            author: ReactSession.get('sales_id'),
            status: stat,
            id: id
        }, headers).then((response) => {
            if (response.data.status) {
                notifySuccess("the user has been modified successfuly");
            } else {
                notifyError("Error when we tried to modify the user");
            }
            setRefresh(true);
            handleTypeClose();
        })
    }
    const editUser = () => {
        var t = true;
        if (usernameUpdate === "") { setMessageUsername("Complete your userName"); t = false }
        else setMessageUsername("");
        if (fullnameUpdate === "") { setMessageUserFullName("Complete your full name"); t = false }
        else setMessageUserFullName("");

        if (t) {
            axios.post(url + "?p=updateUser", {
                author: ReactSession.get('sales_id'),
                userName: usernameUpdate,
                userFullName: fullnameUpdate,
                userEmail: emailUpdate,
                userId: idUpdate,
                addPlaning,
                approvedRequest,
                customerOption,
                userOption,
                personOfCOntactOption,
                addCustomer,
                addTypeOfBusiness,
                addRegion,
                editCustomerP,
                addPersonOfContactToUser,
                listPersonOfContactForUser,
                addPerson,
                editPerson,
                addUserP,
                editUserP,
                resetPasswordP,
                disabledUser,
                viewLogs
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("The user has been update successfully");
                    handleTypeClose();
                } else {
                    notifyError("There is a problem when we update the user");
                }
            });
        }

    };



    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <a onClick={() => paginate(1)} href="#" className="">
                                    {"«"}
                                </a>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage == 1 ? (
                                <a href="#" className="" disabled>
                                    {"<"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage - 1)}
                                    href="#"
                                    className=""
                                >
                                    {"<"}
                                </a>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage == number ? (
                                    <a
                                        onClick={() => paginate(number)}
                                        href="#"
                                        className="bg-red"
                                    >
                                        {number}
                                    </a>
                                ) : (
                                    <a onClick={() => paginate(number)} href="#" className="">
                                        {number}
                                    </a>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage == totalPages ? (
                                <a href="#" className="" disabled>
                                    {">"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage + 1)}
                                    href="#"
                                    className=""
                                >
                                    {">"}
                                </a>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={() => paginate(totalPages)} href="#" className="">
                                    {"»"}
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const filtering = () => {
        if (filterType === 1) {
            if (modalFilterText !== "") {
                const filtered = listUser.filter(filtre => {
                    return filtre.user_fullname.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });
                setListUser(filtered);
                setModalFilter(false);
                paginate(1);
            } else {
                setModalFilter(true);
            }
        } else if (filterType === 2) {
            if (modalFilter !== "") {
                const filtered = listUser.filter(filtre => {
                    return filtre.user_name.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });
                setListUser(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else {
            if (modalFilterText !== "") {
                const filtered = listUser.filter(filtre => {
                    return filtre.user_email.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });

                setListUser(filtered);
                setModalFilter(false);
                paginate(1);
            }
        }

    };

    const handleTypeClose = () => {
        setModalAddUser(false);
        setModalEditUser(false);
        setModalNewPassword(false);
        setModalFilter(false);
        setModalConfirmChangeStatus(false);
        setChangePWD(false)
        setChoosenAdd(0);

        setMessageUserFullName("");
        setMessageUserPassword("");
        setMessageUsername("");

        setFullName("");
        setEditCustomerP(0);
        setAddPlanning(0);
        setApprovedRequest(0);
        setCustomerOption(0);
        setUserOption(0);
        setPersonOfCOntactOption(0);
        setAddCustomer(0);
        setAddTypeOfBusiness(0);
        setAddRegion(0);
        setAddCustomer(0);
        setAddPersonOfContactToUser(0);
        setListPersonOfContactForUser(0);
        setAddPerson(0);
        setEditPerson(0);
        setAddUserP(0);
        setEditUserP(0);
        setResetPasswordP(0);
        setDisabledUser(0);
        setViewLogs(0);

        if (refresh) {
            setRefresh(false);
        } else {
            setRefresh(true);
        }
    }

    function setPerm(permCode) {
        switch (permCode) {
            case 400:
                if (addPlaning === 0) {
                    setAddPlanning(1)
                } else {
                    setAddPlanning(0);
                }
                break;
            case 401:
                if (approvedRequest === 0) {
                    setApprovedRequest(1)
                } else {
                    setApprovedRequest(0);
                }
                break;
            case 402:
                if (customerOption === 0) {
                    setCustomerOption(1)
                } else {
                    setCustomerOption(0);
                }
                break;
            case 403:
                if (userOption === 0) {
                    setUserOption(1)
                } else {
                    setUserOption(0);
                }
                break;
            case 404:
                if (personOfCOntactOption === 0) {
                    setPersonOfCOntactOption(1)
                } else {
                    setPersonOfCOntactOption(0);
                }
                break;
            case 405:
                if (addCustomer === 0) {
                    setAddCustomer(1)
                } else {
                    setAddCustomer(0);
                }
                break;
            case 406:
                if (addTypeOfBusiness === 0) {
                    setAddTypeOfBusiness(1)
                } else {
                    setAddTypeOfBusiness(0);
                }
                break;
            case 407:
                if (addRegion === 0) {
                    setAddRegion(1)
                } else {
                    setAddRegion(0);
                }
                break;
            case 408:
                if (editCustomerP === 0) {
                    setEditCustomerP(1)
                } else {
                    setEditCustomerP(0);
                }
                break;
            case 409:
                if (addPersonOfContactToUser === 0) {
                    setAddPersonOfContactToUser(1)
                } else {
                    setAddPersonOfContactToUser(0);
                }
                break;
            case 410:
                if (listPersonOfContactForUser === 0) {
                    setListPersonOfContactForUser(1)
                } else {
                    setListPersonOfContactForUser(0);
                }
                break;
            case 411:
                if (addPerson === 0) {
                    setAddPerson(1)
                } else {
                    setAddPerson(0);
                }
                break;
            case 412:
                if (editPerson === 0) {
                    setEditPerson(1)
                } else {
                    setEditPerson(0);
                }
                break;
            case 413:
                if (addUserP === 0) {
                    setAddUserP(1)
                } else {
                    setAddUserP(0);
                }
                break;
            case 414:
                if (editUserP === 0) {
                    setEditUserP(1)
                } else {
                    setEditUserP(0);
                }
                break;
            case 415:
                if (resetPasswordP === 0) {
                    setResetPasswordP(1)
                } else {
                    setResetPasswordP(0);
                }
                break;
            case 416:
                if (disabledUser === 0) {
                    setDisabledUser(1)
                } else {
                    setDisabledUser(0);
                }
                break;
            case 417:
                if (viewLogs === 0) {
                    setViewLogs(1)
                } else {
                    setViewLogs(0);
                }
                break;

            default:
                break;
        }
    }

    const getUserPermissions = () => {
        axios.post(url + "?p=getUserPermissions", {
            plan_author: ReactSession.get("sales_id"),
        }, headers).then((response) => {
            if (response.data.status) {
                response.data['result'].map((e) => {
                    switch (e.userPerm_code) {
                        case 403:
                            if (e.userPerm_value === 0) {
                                navigate("/home")
                            }
                            break;
                        case 413:
                            setAddUserBtn(e.userPerm_value);
                            break;
                        case 414:
                            setEditUserBtn(e.userPerm_value);
                            break;
                        case 415:
                            setResetPasswordBtn(e.userPerm_value);
                            break;
                        case 416:
                            setDisabledUserBtn(e.userPerm_value);
                            break;
                        default:
                            break;

                    }
                })
            } else {

            }
        })
    }

    const preapreVariable = () => {
        setAddPlanning(0);
        setApprovedRequest(0);
        setCustomerOption(0);
        setUserOption(0);
        setPersonOfCOntactOption(0);
        setAddCustomer(0);
        setAddTypeOfBusiness(0);
        setAddRegion(0);
        setEditCustomerP(0);
        setAddPersonOfContactToUser(0);
        setListPersonOfContactForUser(0);
        setAddPerson(0);
        setEditPerson(0);
        setAddUserP(0);
        setEditUserP(0);
        setResetPasswordP(0);
        setDisabledUser(0);
        setViewLogs(0)
    }
    const confirmChangeStatus = () => {
        if (user_Status === 0) {
            updateStatus(userCode, 1)
        } else {
            updateStatus(userCode, 0)
        }
    }
    const [user_Status, setUser_Status] = useState(-1);
    const [userCode, setUserCode] = useState(-1);
    const [changePWD, setChangePWD] = useState(false);

    return (
        <>

            <Header />
            <Header_mobile />
            <Modal show={modalAddUser} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className={choosenAdd === 0 ? "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer choosen" : "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer"} onClick={() => setChoosenAdd(0)}>Add user</div>
                        <div className={choosenAdd !== 0 ? "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer choosen" : "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer"} onClick={() => setChoosenAdd(1)}>User Permissions</div>
                        {/* Add User */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {choosenAdd === 0 ? (
                        <form>
                            <div className="form-group mb-4 mt-3">
                                <label className="font-weight-bold">
                                    User Full Name<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="user-fullname"
                                    onChange={(e) => {
                                        setFullName(e.target.value);
                                    }}
                                    value={userFullName}
                                    name="subject"
                                    className="form-control rounded-0"
                                />
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {message_fullName}
                                </div>

                                <label className="font-weight-bold">
                                    User Name<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="username"
                                    onChange={(e) => {
                                        setUserName(e.target.value);
                                    }}
                                    name="subject"
                                    className="form-control rounded-0"
                                />

                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {message_userName}
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label className="font-weight-bold">
                                    Email
                                    <span className="text-danger pl-3"></span>
                                </label>
                                <input
                                    type="email"
                                    id="useremail"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    name="subject"
                                    className="form-control rounded-0"

                                />
                            </div>

                            <div className="form-group mb-4">
                                <label className="font-weight-bold">
                                    Password
                                    <span className="text-danger pl-3"></span>
                                </label>
                                <input
                                    type="password"
                                    id="userpassword"
                                    onChange={(e) => {
                                        setUserPassword(e.target.value);
                                    }}
                                    name="subject"
                                    className="form-control rounded-0"

                                />

                                <div id="site-error" className="text-danger pt-2">
                                    {messagePassword}
                                </div>
                            </div>
                        </form>
                    ) : (
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Planning permissions</Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        <div className="">
                                            <input id="400" type="checkbox" value="400" checked={addPlaning} onChange={(e) => { setPerm(400) }} />

                                            <label htmlFor="400" className="ml-2" >Add planning</label>
                                        </div>

                                        <div className="">
                                            <input id="401" type="checkbox" value="401" checked={approvedRequest} onChange={(e) => { setPerm(401) }} />

                                            <label htmlFor="401" className="ml-2" >Approved request</label>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Setting Permission</Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        <div className="">
                                            <input id="402" type="checkbox" value="402" checked={customerOption} onChange={(e) => { setPerm(402) }} />

                                            <label htmlFor="402" className="ml-2" >Customer Option</label>
                                        </div>

                                        <div className="">
                                            <input id="403" type="checkbox" value="403" checked={userOption} onChange={(e) => { setPerm(403) }} />

                                            <label htmlFor="403" className="ml-2" >User Option</label>
                                        </div>

                                        <div className="">
                                            <input id="404" type="checkbox" value="404" checked={personOfCOntactOption} onChange={(e) => { setPerm(404) }} />

                                            <label htmlFor="404" className="ml-2" >Person of contact Option</label>
                                        </div>

                                        <div className="">
                                            <input id="417" type="checkbox" value="417" checked={viewLogs} onChange={(e) => { setPerm(417) }} />

                                            <label htmlFor="417" className="ml-2" >View logs</label>
                                        </div>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                                Customer Option
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <div className="">
                                                        <input id="405" type="checkbox" value="405" checked={addCustomer} onChange={(e) => { setPerm(405) }} />

                                                        <label htmlFor="405" className="ml-2" >Add customer</label>
                                                    </div>

                                                    <div className="">
                                                        <input id="406" type="checkbox" value="406" checked={addTypeOfBusiness} onChange={(e) => { setPerm(406) }} />

                                                        <label htmlFor="406" className="ml-2" >Add type of Business</label>
                                                    </div>

                                                    <div className="">
                                                        <input id="407" type="checkbox" value="407" checked={addRegion} onChange={(e) => { setPerm(407) }} />

                                                        <label htmlFor="407" className="ml-2" >Add region</label>
                                                    </div>

                                                    <div className="">
                                                        <input id="408" type="checkbox" value="408" checked={editCustomerP} onChange={(e) => { setPerm(408) }} />

                                                        <label htmlFor="408" className="ml-2" >Edit customer</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="409" value="409" checked={addPersonOfContactToUser} onChange={(e) => { setPerm(409) }} />

                                                        <label className="ml-2" htmlFor="409" >Add person of contact to user</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="410" value="410" checked={listPersonOfContactForUser} onChange={(e) => { setPerm(410) }} />

                                                        <label className="ml-2" htmlFor="410">List person of contact for a user</label>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>
                                                Person of contact Option
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <div className="">
                                                        <input type="checkbox" id="411" value="411" checked={addPerson} onChange={(e) => { setPerm(411) }} />

                                                        <label className="ml-2" htmlFor="411" >Add person</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="412" value="412" checked={editPerson} onChange={(e) => { setPerm(412) }} />

                                                        <label className="ml-2" htmlFor="412">Edit person</label>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>User Option</Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <div className="">
                                                        <input type="checkbox" id="413" value="413" checked={addUserP} onChange={(e) => { setPerm(413) }} />

                                                        <label className="ml-2" htmlFor="413">Add user</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="414" value="414" checked={editUserP} onChange={(e) => { setPerm(414) }} />

                                                        <label className="ml-2" htmlFor="414">Edit user</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="415" value="415" checked={resetPasswordP} onChange={(e) => { setPerm(415) }} />

                                                        <label className="ml-2" htmlFor="415" >Reset password</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="416" value="416" checked={disabledUser} onChange={(e) => { setPerm(416) }} />

                                                        <label className="ml-2" htmlFor="416" >Disabled user</label>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleTypeClose} className="btn btn-secondary">Close</button>
                    <button type="button" onClick={() => { addUsers(); }} className="btn btn-success">Add user</button>

                </Modal.Footer>
            </Modal>
            <Modal show={modalEditUser} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className={choosenAdd === 0 ? "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer choosen" : "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer"} onClick={() => setChoosenAdd(0)}>Update user</div>
                        <div className={choosenAdd !== 0 ? "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer choosen" : "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer"} onClick={() => setChoosenAdd(1)}>Update Permissions</div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {choosenAdd === 0 ? (
                        <form>
                            <div className="form-group mb-4 mt-3">
                                <label className="font-weight-bold">
                                    User Full Name<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    onChange={(e) => {
                                        setFullNameUpdate(e.target.value);
                                    }}
                                    value={fullnameUpdate}
                                    name="subject"
                                    className="form-control rounded-0"
                                />
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {message_fullName}
                                </div>

                                <label className="font-weight-bold">
                                    User Name<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    onChange={(e) => {
                                        setUserNameUpdate(e.target.value);
                                    }}
                                    value={usernameUpdate}
                                    name="subject"
                                    className="form-control rounded-0"
                                />

                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {message_userName}
                                </div>
                            </div>

                            <div className="form-group mb-4">
                                <label className="font-weight-bold">
                                    Email
                                    <span className="text-danger pl-3"></span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    type="email"
                                    onChange={(e) => {
                                        setEmailUpdate(e.target.value);
                                    }}
                                    value={emailUpdate}
                                    name="subject"
                                    className="form-control rounded-0"
                                />
                            </div>
                        </form>
                    ) : (
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Planning permissions</Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        <div className="">
                                            <input id="400" type="checkbox" value="400" checked={addPlaning} onChange={(e) => { setPerm(400) }} />

                                            <label htmlFor="400" className="ml-2" >Add planning</label>
                                        </div>

                                        <div className="">
                                            <input id="401" type="checkbox" value="401" checked={approvedRequest} onChange={(e) => { setPerm(401) }} />

                                            <label htmlFor="401" className="ml-2" >Approved request</label>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Setting Permission</Accordion.Header>
                                <Accordion.Body>
                                    <div>
                                        <div className="">
                                            <input type="checkbox" id="402" value="402" checked={customerOption} onChange={(e) => { setPerm(402) }} />

                                            <label className="ml-2" htmlFor="402" >Customer Option</label>
                                        </div>

                                        <div className="">
                                            <input type="checkbox" id="403" value="403" checked={userOption} onChange={(e) => { setPerm(403) }} />

                                            <label className="ml-2" htmlFor="403" >User Option</label>
                                        </div>

                                        <div className="">
                                            <input type="checkbox" value="404" id="404" checked={personOfCOntactOption} onChange={(e) => { setPerm(404) }} />

                                            <label className="ml-2" htmlFor="404" >Person of contact Option</label>
                                        </div>


                                        <div className="">
                                            <input id="417" type="checkbox" value="417" checked={viewLogs} onChange={(e) => { setPerm(417) }} />

                                            <label htmlFor="417" className="ml-2" >View logs</label>
                                        </div>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                                Customer Option
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <div className="">
                                                        <input type="checkbox" value="405" id="405" checked={addCustomer} onChange={(e) => { setPerm(405) }} />

                                                        <label className="ml-2" htmlFor="405" >Add customer</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" value="406" id="406" checked={addTypeOfBusiness} onChange={(e) => { setPerm(406) }} />

                                                        <label className="ml-2" htmlFor="406" >Add type of Business</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="407" value="407" checked={addRegion} onChange={(e) => { setPerm(407) }} />

                                                        <label className="ml-2" htmlFor="407" >Add region</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="408" value="408" checked={editCustomerP} onChange={(e) => { setPerm(408) }} />

                                                        <label className="ml-2" htmlFor="408" >Edit customer</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="409" value="409" checked={addPersonOfContactToUser} onChange={(e) => { setPerm(409) }} />

                                                        <label className="ml-2" htmlFor="409" >Add person of contact to user</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" value="410" id="410" checked={listPersonOfContactForUser} onChange={(e) => { setPerm(410) }} />

                                                        <label className="ml-2" htmlFor="410" >List person of contact for a user</label>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>
                                                Person of contact Option
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <div className="">
                                                        <input type="checkbox" value="411" id="411" checked={addPerson} onChange={(e) => { setPerm(411) }} />

                                                        <label className="ml-2" htmlFor="411" >Add person</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="412" value="412" checked={editPerson} onChange={(e) => { setPerm(412) }} />

                                                        <label className="ml-2" htmlFor="412" >Edit person</label>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>User Option</Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <div className="">
                                                        <input type="checkbox" id="413" value="413" checked={addUserP} onChange={(e) => { setPerm(413) }} />

                                                        <label className="ml-2" htmlFor="413" >Add user</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="414" value="414" checked={editUserP} onChange={(e) => { setPerm(414) }} />

                                                        <label className="ml-2" htmlFor="414" >Edit user</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="415" value="415" checked={resetPasswordP} onChange={(e) => { setPerm(415) }} />

                                                        <label className="ml-2" htmlFor="415" >Reset password</label>
                                                    </div>

                                                    <div className="">
                                                        <input type="checkbox" id="416" value="416" checked={disabledUser} onChange={(e) => { setPerm(416) }} />

                                                        <label className="ml-2" htmlFor="416" >Disabled user</label>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-success" onClick={editUser}>Update user</button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalNewPassword} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Reset password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {changePWD ?

                        <form>

                            <div className="form-group mb-4 mt-3">
                                <label className="font-weight-bold">
                                    New Password
                                    <span className="text-danger pl-3">*</span>
                                </label>
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    <label>The new password is {newUserPassword}</label>
                                </div>
                            </div>

                        </form>
                        : <label>Are you sure ? </label>}
                </Modal.Body>
                <Modal.Footer>
                    {!changePWD ?
                        <>
                            <button type="button" className="btn btn-primary" onClick={() => { setChangePWD(true); resetPassword() }}>Confirm</button>
                        </> : <></>}
                    <button type="button" className="btn btn-warning" onClick={handleTypeClose}>Close</button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalFilter} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                {
                                    filterType === 1 ? (
                                        "Full Name"
                                    ) : filterType === 2 ? (
                                        "User Name"
                                    ) : (
                                        "Email"
                                    )
                                }
                                <span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="eq-subject"
                                onChange={(e) => {
                                    setModalFilterText(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">

                            </div>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-success" onClick={filtering}>Add filter</button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalConfirmChangeStatus} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-success" onClick={confirmChangeStatus}>Confirm</button>
                </Modal.Footer>
            </Modal>

            <div
                className="d-flex flex-column flex-column-fluid "
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}
            >
                <div className="col-xl-12 center-block " align="center" style={{ backgroundColor: "#eef0f8" }} >
                    <div className="col-xl-8 bg-white mt-50 center-block min-h-650px" align="center" >
                        <div className="pt-10 w-100 h-100px" style={{ marginLeft: "0.8rem" }}>
                            <h3 className="float-left pt-2 pr-3">List of user </h3>
                            <span className="float-left">
                                <BiRefresh
                                    onClick={() => {
                                        getListUser();
                                    }}
                                    className="cursor-pointer display-4"
                                />
                            </span>
                            {addUserBtn === 1 ? (
                                <button
                                    onClick={() => { preapreVariable(); setModalAddUser(true) }}
                                    id="crticket-btn"
                                    className="btn btn-defaut text-white bg-red font-weight-bold float-right mr-5"
                                    data-toggle="modal"
                                    data-target="#addticketModal"
                                >
                                    Add user
                                </button>
                            ) : (<></>)}

                        </div>

                        <div className="h-550px overflow-auto">
                            <table id="customers max-h-300px" className="table">
                                <thead className="position-fixe">
                                    <tr>
                                        <th scope="col" className="w-25px"></th>
                                        <th scope="col" className="w-25px">
                                            #
                                        </th>

                                        <th scope="col" className="text-left">
                                            User full name
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketidModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(1); setListUser(listUser1) }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Username
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketidModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(2); setListUser(listUser1) }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            User email
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(3); setListUser(listUser1) }}></i>
                                            </a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="resultdata">
                                    {
                                        currentPosts ? (
                                            currentPosts.map((d, index) => (
                                                <tr
                                                    className="text-info"
                                                    key={d.user_id}
                                                >
                                                    <Dropdown className="dropdown">
                                                        <Dropdown.Toggle
                                                            variant="dropdown-basic"
                                                            className="w-60px py-0 text-white p-0 m-0"
                                                        >
                                                            <div
                                                                className="dropdown dropdown-inline bg-light2 py-0 rounded"
                                                                data-toggle="dropdown"

                                                            >
                                                                <a

                                                                    type="button"
                                                                    className="btn btn-light2 btn-icon py-0 btn-sm"
                                                                >
                                                                    <i className="ki ki-bold-more-hor"></i>
                                                                </a>
                                                            </div>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="">
                                                            {editUserBtn === 1 ? (
                                                                <Dropdown.Item
                                                                    href="#"
                                                                    className="dropdown-item border-bottom border-top"
                                                                    onClick={() => { getUserInfo(d.user_id); setModalEditUser(true); }}
                                                                >
                                                                    <i className="far fa-edit"></i>{" "}
                                                                    <span className="pl-3">Edit User</span>
                                                                </Dropdown.Item>
                                                            ) : (<></>)}

                                                            {resetPasswordBtn === 1 && d.user_id !== 1 ? (
                                                                <Dropdown.Item
                                                                    href="#"
                                                                    onClick={() => { setModalNewPassword(true); setUserCode(d.user_id); }}
                                                                    className="dropdown-item border-bottom border-top"
                                                                >
                                                                    <i className="far fa-list-alt"></i>{" "}
                                                                    <span className="pl-3">reset password</span>
                                                                </Dropdown.Item>
                                                            ) : (
                                                                <></>
                                                            )}

                                                            {disabledUserBtn === 1 && d.user_id !== 1 ? (
                                                                <Dropdown.Item
                                                                    href="#"
                                                                    onClick={() => { setModalConfirmChangeStatus(true); setUser_Status(d.user_status); setUserCode(d.user_id) }}
                                                                    className="dropdown-item border-bottom"
                                                                >
                                                                    {/* <i className="la la-remove"></i>{" "} */}
                                                                    {/* <span className="pl-3">{d.user_status === 0 ? ("Active") : ("Inactive")}</span> */}
                                                                    {d.user_status === 0 ? <><FcPlus size={20} /> <span className="pl-3">Active</span></> : <><FcMinus size={20} /> <span className="pl-3">Desactive</span></>}
                                                                </Dropdown.Item>
                                                            ) : (
                                                                <></>
                                                            )}

                                                        </Dropdown.Menu>
                                                    </Dropdown>


                                                    <td id="tb-assigned-to" className="text-left text-dark">
                                                        {d.user_id}
                                                    </td>

                                                    <td id="tb-assigned-to" className="text-left text-dark">
                                                        {d.user_fullname}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left text-dark">
                                                        {d.user_name}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left text-dark">
                                                        {d.user_email}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={11} className="text-center pt-20 fs-16">
                                                    No data found
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default Users;