import axios from "axios";
import { useEffect, useState } from "react";
import "../Assets/css/bootstrap.min.css";
import Header from "./header";
import Header_mobile from "./header_mobile";
import Footer from "./footer";
import { ReactSession } from "react-client-session";
import { useAsyncError, useNavigate } from "react-router-dom";



function Home() {
    return (
        <>
            <Header />
            <Header_mobile />
            <div
                className="d-flex flex-column flex-column-fluid "
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}
            >

            </div>
            <Footer />
        </>
    );
}

export default Home;