import React, { useState, useRef } from 'react';
import { Form, ProgressBar, Alert, ListGroup } from 'react-bootstrap';
import axios from 'axios';

function FileUpload({ cCode, dCode, author }) {
    const url = process.env.REACT_APP_URL;
    const headers = JSON.parse(process.env.REACT_APP_CONFIG);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const fileInputRef = useRef();
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'docx', 'xlsx', 'xls'];

        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (allowedExtensions.includes(fileExtension)) {
                setSelectedFile(file);
                setErrorMessage(null);
                handleFileUpload(file);
            } else {
                setSelectedFile(null);
                setErrorMessage(`L'extension .${fileExtension} n'est pas autorisée`);
            }
        }

    };

    const handleFileUpload = async (file) => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('cCode', cCode);
            formData.append('dCode', dCode);
            formData.append('author', author);

            try {
                const response = await axios.post(url + '?p=uploadXY', formData, headers, {
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(progress);
                    }
                });

                setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, file.name]);
                setSelectedFile(null);
                setUploadProgress(0);
                fileInputRef.current.value = null;

            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        // setSelectedFile(event.dataTransfer.files[0]);

        const file = event.dataTransfer.files[0];
        const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'docx', 'xlsx', 'xls'];

        if (file) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (allowedExtensions.includes(fileExtension)) {
                setSelectedFile(file);
                setErrorMessage(null);
                handleFileUpload(file)
            } else {
                setSelectedFile(null);
                setErrorMessage(`L'extension .${fileExtension} n'est pas autorisée`);
            }
        }
    };

    return (
        <Form>
            <Form.Group controlId="formFile">
                <div
                    style={{
                        border: '2px dashed #6c757d',
                        padding: '20px',
                        textAlign: 'center'
                    }}
                    onClick={() => fileInputRef.current.click()}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                >
                    {selectedFile ? selectedFile.name : 'Cliquez ici ou glissez-déposez des fichiers'}
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </Form.Group>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} className='my-2' />
            <div className='py-1 my-2 h-100px overflow-auto'>
                <ListGroup>
                    {[...uploadedFiles].reverse().map((fileName) => (
                        <ListGroup.Item key={fileName} variant="success" className='my-1'>
                            {fileName}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </div>
        </Form>
    );
}

export default FileUpload;
