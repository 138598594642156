import axios from "axios";
import { useEffect, useState } from "react";
import "../Assets/css/bootstrap.min.css";
import Header from "./header";
import Header_mobile from "./header_mobile";
import Footer from "./footer";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { BiRefresh } from "react-icons/bi";
import { ReactSession } from "react-client-session";
import { useNavigate } from "react-router-dom";
function Pcontact() {
    const url = process.env.REACT_APP_URL;
    const headers = JSON.parse(process.env.REACT_APP_CONFIG);
    const navigate = useNavigate()
    const [refresh, setRefresh] = useState(false);

    const [modalAddPersonOfContact, setModalAddPersonOfContact] = useState(false);
    const [modalEditPersonOfContact, setModalEditPersonOfContact] = useState(false);
    const [modalFilter, setModalFilter] = useState(false);

    const [fullName, setFullName] = useState("");
    const [position, setPosition] = useState("");
    const [region, setRegion] = useState("");
    const [address, setAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(0);
    const [email, setEmail] = useState("");

    const [editCode, setEdit_code] = useState(0);
    const [edit_name, setEdit_name] = useState("");
    const [edit_position, setEdit_position] = useState(0);
    const [edit_address, setEdit_address] = useState("");
    const [edit_phoneNumber, setEdit_phoneNumber] = useState(0);
    const [edit_email, setEdit_email] = useState("");

    const [filterType, setFilterType] = useState("");
    const [modalFilterText, setModalFilterText] = useState("");




    const [message_FullName, setMessage_FullName] = useState("");
    const [message_Position, setMessage_Position] = useState("");
    const [message_Address, setMessage_Address] = useState("");
    const [message_PhoneNumber, setMessage_PhoneNumber] = useState("");
    const [message_filter, setMessage_filter] = useState("");


    const [listPersonOfContact, setListPersonOfContact] = useState([]);
    const [listPersonOfContact1, setListPersonOfContact1] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [linePerPage, setlinePerPage] = useState(10);
    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const currentPosts = listPersonOfContact.slice(indexOfFirstPost, indexOfLastPost);


    const [personOfCOntactOption, setPersonOfCOntactOption] = useState(0);
    const [addPerson, setAddPerson] = useState(0);
    const [editPerson, setEditPerson] = useState(0);
    // const [personOfCOntactOption, setPersonOfCOntactOption] = useState(0);



    useEffect(() => {
        getUserPermissions();
        setFullName("");
        setPosition("");
        setAddress("");
        setPhoneNumber("");
        setEmail("");


        getListPersonOfContact();

        setMessage_FullName("");
        setMessage_Position("");
        setMessage_Address("");
        setMessage_PhoneNumber("");
    }, [refresh]);

    const handleTypeClose = () => {
        setModalAddPersonOfContact(false);
        setModalEditPersonOfContact(false);
        setModalFilter(false);


        setMessage_FullName("");
        setMessage_Position("");
        setMessage_Address("");
        setMessage_PhoneNumber("");

        setEdit_name("");
        setEdit_position("");
        setEdit_address("");
        setEdit_phoneNumber(0);
        setEdit_email("");


        if (refresh) {
            setRefresh(false);
        } else {
            setRefresh(true);
        }
    }
    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const beforeEdit = (pCode) => {
        setEdit_code(pCode);
        setModalEditPersonOfContact(true);
        getDetailPOC(pCode);
    }

    const filtering = () => {
        if (filterType === 1) {
            if (modalFilterText !== "") {
                const filtered = listPersonOfContact.filter(filtre => {
                    return filtre.contact_name.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });
                setListPersonOfContact(filtered);
                setModalFilter(false);
                paginate(1);
            } else {
                setModalFilter(true);
            }
        } else if (filterType === 2) {
            if (modalFilter !== "") {
                const filtered = listPersonOfContact.filter(filtre => {
                    return filtre.contact_position.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });
                setListPersonOfContact(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else if (filterType === 3) {
            if (modalFilter !== "") {
                const filtered = listPersonOfContact.filter(filtre => {
                    return filtre.cust_name.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });
                setListPersonOfContact(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else if (filterType === 4) {
            if (modalFilter !== "") {
                const filtered = listPersonOfContact.filter(filtre => {
                    return filtre.contact_adress.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });
                setListPersonOfContact(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else {
            if (modalFilterText !== "") {
                const filtered = listPersonOfContact.filter(filtre => {
                    return filtre.contact_email.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });

                setListPersonOfContact(filtered);
                setModalFilter(false);
                paginate(1);
            }
        }

    };

    const addPersonOfContact = () => {
        var x = 0;
        if (fullName === "") { setMessage_FullName("complete the name"); x = 1 }
        else setMessage_FullName("");
        if (position === "") { setMessage_Position("complete the position"); x = 1 }
        else setMessage_Position("");
        if (address === "") { setMessage_Address("Complete the address"); x = 1 }
        else setMessage_Address("");
        if (checkNumber(phoneNumber) === 0) { setMessage_PhoneNumber("Complete the phone number"); x = 1 }
        else setMessage_PhoneNumber("");
        console.log(ReactSession.get('sales_id'),);
        if (x === 0) {
            axios.post(url + "?p=addPersonOfContact", {
                author: ReactSession.get('sales_id'),
                fullName: fullName,
                position: position,
                address: address,
                phoneNumber: checkNumber(phoneNumber),
                email: email
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("The person has been added succesfully");
                    handleTypeClose();
                } else {
                    notifyError("error when we add the person");
                }
            })
        }
    }

    const editPOC = () => {
        var x = 0;
        if (edit_name === "") { setMessage_FullName("complete the name"); x = 1 }
        else setMessage_FullName("");
        if (edit_position === "") { setMessage_Position("complete the position"); x = 1 }
        else setMessage_Position("");
        if (edit_address === "") { setMessage_Address("Complete the address"); x = 1 }
        else setMessage_Address("");
        if (checkNumber(edit_phoneNumber) === 0) { setMessage_PhoneNumber("Complete the phone number"); x = 1 }
        else setMessage_PhoneNumber("");

        if (x === 0) {
            axios.post(url + "?p=editPOC", {
                author: ReactSession.get('sales_id'),
                contact_code: editCode,
                contact_name: edit_name,
                contact_position: edit_position,
                contact_adress: edit_address,
                contact_phoneNumber: checkNumber(edit_phoneNumber),
                contact_email: edit_email
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("the person has been updated succesfully");
                    handleTypeClose();
                } else {
                    notifyError("Error when we update the user");
                }
            })
        }
    }

    const getListPersonOfContact = () => {
        axios.post(url + "?p=getPersonOfContact", {
            task: "contact"
        }, headers).then((response) => {
            if (response.data.status) {
                setListPersonOfContact(response.data.list);
                setListPersonOfContact1(response.data.list);
            } else {
            }
        })
    }

    const getDetailPOC = (pCode) => {
        axios.post(url + "?p=getDetailPOC", {
            contact_code: pCode
        }, headers).then((response) => {
            if (response.data.status) {
                setEdit_name(response.data.list[0].contact_name);
                setEdit_position(response.data.list[0].contact_position);
                setEdit_address(response.data.list[0].contact_adress);
                setEdit_phoneNumber(response.data.list[0].contact_number);
                setEdit_email(response.data.list[0].contact_email);
            }
        })
    }

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <a onClick={() => paginate(1)} href="#" className="">
                                    {"«"}
                                </a>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage == 1 ? (
                                <a href="#" className="" disabled>
                                    {"<"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage - 1)}
                                    href="#"
                                    className=""
                                >
                                    {"<"}
                                </a>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage == number ? (
                                    <a
                                        onClick={() => paginate(number)}
                                        href="#"
                                        className="bg-red"
                                    >
                                        {number}
                                    </a>
                                ) : (
                                    <a onClick={() => paginate(number)} href="#" className="">
                                        {number}
                                    </a>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage == totalPages ? (
                                <a href="#" className="" disabled>
                                    {">"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage + 1)}
                                    href="#"
                                    className=""
                                >
                                    {">"}
                                </a>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={() => paginate(totalPages)} href="#" className="">
                                    {"»"}
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    function checkNumber(number) {
        var phone_number = '' + number;
        if (phone_number.length === 10 && phone_number[0] === '0') {
            if (phone_number[1] === "9" || phone_number[1] === "8")
                return (parseInt(phone_number.substring(1, 10), 10));
            else
                return (0)
        } else if (phone_number.length === 12) {
            if (phone_number.substring(0, 3) === "243") {
                if (phone_number[3] === "9" || phone_number[3] === "8")
                    return (parseInt(phone_number.substring(3, 13), 10));

                else
                    return (0)
            } else {
                return (0);
            }
        } else if (phone_number.length === 13) {
            if (phone_number.substring(0, 4) === "+243") {
                if (phone_number[4] === "9" || phone_number[4] === "8")
                    return (parseInt(phone_number.substring(4, 14), 10));

                else
                    return (0)
            } else {
                return (0)
            }
        } else if (phone_number.length === 14) {
            if (phone_number.substring(0, 5) === "00243") {
                if (phone_number[5] === "9" || phone_number[5] === "8")
                    return (parseInt(phone_number.substring(5, 15), 10));
                else
                    return (0)
            } else {
                return (0)
            }
        } else if (phone_number.length === 9) {
            if (phone_number[0] === "9" || phone_number[0] === "8") {
                return (parseInt(phone_number, 10));
            } else {
                return (0);
            }
        } else {
            return (0);
        }
    }

    const getUserPermissions = () => {
        axios.post(url + "?p=getUserPermissions", {
            plan_author: ReactSession.get("sales_id"),
        }, headers).then((response) => {
            if (response.data.status) {
                response.data['result'].map((e) => {
                    switch (e.userPerm_code) {
                        case 404:
                            if (e.userPerm_value === 0) {
                                navigate("/home")
                            }
                            break;
                        case 411:
                            setAddPerson(e.userPerm_value);

                            break;
                        case 412:
                            setEditPerson(e.userPerm_value);
                            break;
                        default:
                            break;

                    }
                })
            } else {

            }
        })
    }


    return (
        <>
            <Header />
            <Header_mobile />
            <Modal show={modalAddPersonOfContact} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add person of contact</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="row g-3 ">
                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Full Name<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="user-fullname"
                                onChange={(e) => {
                                    setFullName(e.target.value);
                                }}
                                value={fullName}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_FullName}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Position<span className="text-danger pl-3">*</span>
                            </label>

                            <input
                                id="user-position"
                                onChange={(e) => {
                                    setPosition(e.target.value);
                                }}
                                value={position}
                                name="position"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_Position}
                            </div>
                        </div>

                        <div className="form-group mb-1 col-md-6">
                            <label className="font-weight-bold">
                                Address
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="text"
                                id="user"
                                onChange={(e) => {
                                    setAddress(e.target.value);
                                }}
                                value={address}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_Address}
                            </div>
                        </div>

                        <div className="col-md-6 mb-1">
                            <label className="font-weight-bold">
                                Phone number
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="number"
                                id="user"
                                onChange={(e) => {
                                    setPhoneNumber(e.target.value);
                                }}
                                value={phoneNumber}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_PhoneNumber}
                            </div>
                        </div>

                        <div className="form-group mb-4">
                            <label className="font-weight-bold">
                                Email
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="email"
                                id="user"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                value={email}
                                name="subject"
                                className="form-control rounded-0"
                            />
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleTypeClose} className="btn btn-secondary">Close</button>
                    <button type="button" onClick={addPersonOfContact} className="btn btn-success">Add customer</button>

                </Modal.Footer>
            </Modal>
            <Modal show={modalEditPersonOfContact} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit a person</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="row g-3 ">
                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Name<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="user-fullname"
                                onChange={(e) => {
                                    setEdit_name(e.target.value);
                                }}
                                value={edit_name}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_FullName}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Position<span className="text-danger pl-3">*</span>
                            </label>

                            <input
                                id="user-position"
                                onChange={(e) => {
                                    setEdit_position(e.target.value);
                                }}
                                value={edit_position}
                                name="position"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_Position}
                            </div>
                        </div>

                        <div className="form-group mb-1 col-md-6">
                            <label className="font-weight-bold">
                                Address
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="text"
                                id="user"
                                onChange={(e) => {
                                    setEdit_address(e.target.value);
                                }}
                                value={edit_address}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_Address}
                            </div>
                        </div>

                        <div className="col-md-6 mb-1">
                            <label className="font-weight-bold">
                                Phone number
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="number"
                                id="user"
                                onChange={(e) => {
                                    setEdit_phoneNumber(e.target.value);
                                }}
                                value={edit_phoneNumber}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_PhoneNumber}
                            </div>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                            <label className="font-weight-bold">
                                Email
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="email"
                                id="user"
                                onChange={(e) => {
                                    setEdit_email(e.target.value);
                                }}
                                value={edit_email}
                                name="subject"
                                className="form-control rounded-0"
                            />
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleTypeClose} className="btn btn-secondary">Close</button>
                    <button type="button" onClick={editPOC} className="btn btn-success">Edit customer</button>

                </Modal.Footer>
            </Modal>
            <Modal show={modalFilter} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                {
                                    filterType === 1 ? (
                                        "Name"
                                    ) : filterType === 2 ? (
                                        "Position"
                                    ) : filterType === 3 ? (
                                        "Name of customer"
                                    ) : filterType === 4 ? (
                                        "Address"
                                    ) : (
                                        "Email"
                                    )
                                }
                                <span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="eq-subject"
                                onChange={(e) => {
                                    setModalFilterText(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_filter}
                            </div>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-success" onClick={filtering}>Add filter</button>
                </Modal.Footer>
            </Modal>



            <div
                className="d-flex flex-column flex-column-fluid "
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}
            >
                <div className="col-xl-12 center-block " align="center" style={{ backgroundColor: "#eef0f8" }} >
                    <div className="col-xl-8 bg-white mt-50 center-block min-h-650px" align="center" >
                        <div className="pt-10 w-100 h-100px" style={{ marginLeft: "0.8rem" }}>
                            <h3 className="float-left pt-2 pr-3">Person of contact </h3>
                            <span className="float-left">
                                <BiRefresh
                                    onClick={() => {
                                        getListPersonOfContact();
                                    }}
                                    className="cursor-pointer display-4"
                                />
                            </span>
                            {addPerson === 1 ? (
                                <button
                                    onClick={() => setModalAddPersonOfContact(true)}
                                    id="crticket-btn"
                                    className="btn btn-defaut text-white bg-red font-weight-bold float-right mr-10"
                                    data-toggle="modal"
                                    data-target="#addticketModal"
                                >
                                    Add person
                                </button>
                            ) : (<></>)}

                        </div>

                        <div className="h-full overflow-auto">
                            <table id="customers max-h-300px" className="table ">
                                <thead className="position-fixe">
                                    <tr>
                                        <th scope="col" className="w-25px"></th>
                                        <th scope="col" className="w-25px">
                                            #
                                        </th>

                                        <th scope="col" className="text-left">
                                            Name of contact
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketidModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(1); setListPersonOfContact(listPersonOfContact1) }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Position
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketidModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(2); setListPersonOfContact(listPersonOfContact1) }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Name of customer
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketidModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(3); setListPersonOfContact(listPersonOfContact1) }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Adress
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(4); setListPersonOfContact(listPersonOfContact1) }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Email
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(5); setListPersonOfContact(listPersonOfContact1) }}> </i>
                                            </a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="resultdata">
                                    {currentPosts ? (
                                        currentPosts.map((d, index) => (
                                            <tr
                                                key={d.contact_code}
                                                className="text-info"
                                            >
                                                {editPerson === 1 ? (
                                                    <Dropdown className="dropdown">
                                                        <Dropdown.Toggle
                                                            variant="dropdown-basic"
                                                            className="w-60px py-0 text-white p-0 m-0"
                                                            onClick={() => { beforeEdit(d.contact_code, d.contact_region) }}
                                                        >
                                                            <div
                                                                className="dropdown dropdown-inline bg-light2 py-0 rounded"
                                                                data-toggle="dropdown"

                                                            >
                                                                <a

                                                                    type="button"
                                                                    className="btn btn-light2 btn-icon py-0 btn-sm"
                                                                >
                                                                    <i className="far fa-edit"></i>
                                                                </a>
                                                            </div>
                                                        </Dropdown.Toggle>
                                                    </Dropdown>
                                                ) : (<td></td>)}

                                                <td id="tb-assigned-to" className="text-left text-dark">
                                                    {d.contact_code}
                                                </td>
                                                <td id="tb-assigned-to" className="text-left text-dark">
                                                    {d.contact_name}
                                                </td>

                                                <td id="tb-assigned-to" className="text-left text-dark">
                                                    {d.contact_position}
                                                </td>
                                                <td id="tb-assigned-to" className="text-left text-dark">
                                                    {
                                                        d.cust_name === null ? (
                                                            <p className="text-danger">UNISSIGNED</p>

                                                        ) : (
                                                            d.cust_name
                                                        )
                                                    }
                                                </td>
                                                <td id="tb-created-date" className="text-left text-dark">
                                                    {d.contact_adress}
                                                </td>
                                                <td id="tb-created-date" className="text-left text-dark">
                                                    {d.contact_email}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={11} className="text-center pt-20 fs-16">
                                                No data found
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>


                    </div>
                    <div className="card-footer pt-2 pb-2 col-8 mb-0 bg-white">
                        <div className="d-flex justify-content-center">
                            <Pagination
                                postsPerPage={linePerPage}
                                totalPosts={listPersonOfContact.length}
                                paginate={paginate}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Pcontact;