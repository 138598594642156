import axios from "axios";
import { useEffect, useState } from "react";
import logo from "../Assets/img/auto-logo-circle.png";
import { useAsyncError, useNavigate } from "react-router-dom";
import { ReactSession } from "react-client-session";
import { useCookies } from "react-cookie";
var bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);

function Login() {
    const url = process.env.REACT_APP_URL;
    const headers = JSON.parse(process.env.REACT_APP_CONFIG);
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [errorMessage, setErrorMessage] = useState('');
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");


    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage("");
        axios.post(url + "?p=logUser", {
            userName: userName
        }, headers).then((response) => {
            if (response.data.type === true) {
                if (response.data.text) {
                    if (bcrypt.compareSync(password, response.data.ref)) {
                        ReactSession.set("sales_id", response.data.id);
                        ReactSession.set("sales_username", response.data.username);
                        ReactSession.set("sales_userfullname", response.data.userfullname);
                        ReactSession.set("sales_mail", response.data.mail);
                        setCookie("username", response.data.username);
                        setCookie("id", response.data.id);
                        setCookie("mail", response.data.mail);

                        localStorage.setItem("cacheData", userName);
                        navigate("/home");
                    } else {
                        setErrorMessage("password is incorrect");
                    }
                } else {
                    setErrorMessage("your account is inactif");
                }

            } else {
                setErrorMessage("username not found");
            }
        });
    };

    const getAllCacheData = async () => {
        const cachedData = localStorage.getItem("cachedData");
        if (cachedData > 0) {
            navigate("/home");
        } else {
            navigate('/')
        }
    };

    useEffect(() => {
        getAllCacheData();
    }, []);

    return (
        <>

            <div
                className="mt-auto bg-white"
                style={{ minHeight: "100vh", position: "relative" }}
            >
                <div
                    className="d-flex flex-column w-100 flex-root"
                    style={{
                        margin: 0,
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                    }}
                >
                    <div className="login login-2 d-flex flex-row-fluid" id="kt_login">
                        <div className="d-flex flex-center flex-row-fluid p-0">
                            <div className="login-form text-center position-relative overflow-hidden">
                                <div className="d-flex flex-center mb-3">
                                    <a href="">
                                        <img src={logo} className="max-h-150px" alt="" />
                                    </a>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className="login-signin pb-5 pt-3">
                                        <div className="mb-3">
                                            <h2>Sign in</h2>
                                        </div>
                                        <div className="mb-8 mt-5">
                                            <div className="text-muted fs-11-5">
                                                Enter your login informations
                                            </div>
                                        </div>
                                        <div id="login-form">
                                            <div className="form-group mb-5">
                                                <input
                                                    type="text"
                                                    name="username"
                                                    id="username"
                                                    onChange={(e) => {
                                                        setUserName(e.target.value);
                                                    }}
                                                    className="form-control form-control-solid h-auto rounded-0 border py-4 px-8 fs-14-5 min-w-100"
                                                    required
                                                    placeholder="username"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <div className="form-group mb-4">
                                                <input
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                    }}
                                                    className="form-control form-control-solid h-auto rounded-0 border py-4 px-8 fs-14-5"
                                                    placeholder="Password"
                                                    autoComplete="off"
                                                    required
                                                />
                                            </div>

                                            <div className="form-group d-flex justify-content-between align-items-center mt-6">
                                                <div className="checkbox-inline mr-20 pl-0">
                                                    <label className="checkbox m-0 text-muted min-w-250px">
                                                        <input
                                                            type="checkbox"
                                                            name="remember"
                                                            id="remember-me"
                                                        />
                                                        <span></span>
                                                        Remember me
                                                    </label>
                                                </div>
                                                <span></span>
                                            </div>
                                            <p id="error-text" className="text-danger pt-0">
                                                {errorMessage}
                                            </p>
                                            <button
                                                type="submit"
                                                className="btn btn-lg text-white rounded-0 btn-block fs-15-5 bg-red"
                                                name="btn-connexion"
                                            >
                                                Login
                                            </button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Login;