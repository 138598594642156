
import './App.css';
import "./Assets/custom/css/style.bundle.css";
import "./Assets/custom/plugins/global/plugins.bundle.css";
import { ReactSession } from "react-client-session";
import Authentication from "./Authentication";
import Login from "./component/Login";
import Home from "./component/Home";
import Customers from './component/Customers';
import Pcontact from './component/Pcontact';
import FileUpload from './component/FileUpload';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Users from './component/Users';
import Planning from './component/Planning';
import Logs from './component/Logs';

function App() {
  ReactSession.setStoreType("localStorage");

  return (
    <Router>
      <div className="App">
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/Login" element={<Login />} />

                <Route element={<Authentication />}>
                  <Route exact path="/Home" element={<Home />} />
                  <Route exact path="/Customers" element={<Customers />} />
                  <Route exact path="/Pcontact" element={<Pcontact />} />
                  <Route exact path="/Users" element={<Users />} />
                  <Route exact path="/Planning" element={<Planning />} />
                  <Route exact path="/FileUpload" element={<FileUpload />} />
                  <Route exact path="/Logs" element={<Logs />} />
                </Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
