import axios from "axios";
import { useEffect, useState } from "react";
import "../Assets/css/bootstrap.min.css";
import Header from "./header";
import Header_mobile from "./header_mobile";
import Footer from "./footer";
import { ReactSession } from "react-client-session";
import { useAsyncError, useNavigate } from "react-router-dom";

import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import toast, { Toaster } from "react-hot-toast";
import { BiRefresh } from "react-icons/bi";


function Customers() {
    const url = process.env.REACT_APP_URL;
    const headers = JSON.parse(process.env.REACT_APP_CONFIG);
    const navigate = useNavigate()
    const [refresh, setRefresh] = useState(false);


    const [modalAddRegion, setModalAddRegion] = useState(false);
    const [modalAddTypeOfBusiness, setModalAddTypeOfBusiness] = useState(false);
    const [modalAddCustomer, setModalAddCustomer] = useState(false);
    const [modalEditCustomer, setModalEditCustomer] = useState(false);
    const [modalAddPersonOfContact, setModalAddPersonOfContact] = useState(false);
    const [modalPersonOfContact, setModalPersonOfContact] = useState(false);
    const [modalNewPassword, setModalNewPassword] = useState(false);
    const [modalFilter, setModalFilter] = useState(false);


    const [addRegionText, setAddRegionText] = useState("");
    const [addTypeOfBusinessText, setAddTypeOfBusinessText] = useState("");
    const [custCode, setCustCode] = useState(0);
    const [custName, setCustName] = useState("");
    const [custUsername, setCustUsername] = useState("");
    const [custRegion, setCustRegion] = useState(0);
    const [custAddress, setCustAddress] = useState("");
    const [custTypeOfBusiness, setCustTypeOfBusiness] = useState(-1);
    const [custSize, setCustSize] = useState("");
    const [custPhoneNumber, setCustPhoneNumber] = useState(0);
    const [custEmail, setCustEmail] = useState("");
    const [custPwd, setCustPwd] = useState("");
    const [custPermTBR, setCustPermTBR] = useState(0);
    const [custPermOTR, setCustPermOTR] = useState(0);
    const [custPermOIL, setCustPermOIL] = useState(0);
    const [custNewPassword, setCustNewPassword] = useState("");

    const [custAddPOCName, setCustAddPOCName] = useState("");
    const [custAddPOCCode, setCustAddPOCCode] = useState(0);
    const [POCName, setPOCName] = useState("");
    const [custCodeUpdate, setCustCodeUpdate] = useState(0);
    const [custBusCodeUpdate, setCustBusCode] = useState(0);
    const [modalFilterText, setModalFilterText] = useState("");
    const [filterType, setFilterType] = useState("");

    const [region, setRegion] = useState(0);
    const [tob, setob] = useState(0);

    const [edit_code, setEdit_code] = useState("");
    const [edit_name, setEdit_name] = useState("");
    const [edit_username, setEdit_username] = useState("");
    const [edit_regionCode, setEdit_regionCode] = useState(0);
    const [edit_region, setEdit_region] = useState("");
    const [edit_address, setEdit_address] = useState("");
    const [edit_typeOfBusinessCode, setEdit_typeOfBusinessCode] = useState(0);
    const [edit_typeOfBusiness, setEdit_typeOfBusiness] = useState("");
    const [edit_size, setEdit_size] = useState("");
    const [edit_phoneNumber, setEdit_phoneNumber] = useState(0);
    const [edit_email, setEdit_email] = useState("");
    const [edit_permTBR, setEdit_permTBR] = useState(0);
    const [edit_permOTR, setEdit_permOTR] = useState(0);
    const [edit_permOIL, setEdit_permOIL] = useState(0);


    const [message_CustName, setMessage_CustName] = useState("");
    const [message_CustRegion, setMessage_CustRegion] = useState("");
    const [message_CustAddress, setMessage_CustAddress] = useState("");
    const [message_CustTypeOfBusiness, setMessage_CustTypeOfBusiness] = useState("");
    const [message_CustSize, setMessage_custSize] = useState("");
    const [message_CustPhoneNumber, setMessage_CustPhoneNumber] = useState("");
    const [message_AddTypeOfBusinessText, setMessage_AddTypeOfBusinessText] = useState("");
    const [message_AddRegionText, setMessage_AddRegionText] = useState("");
    const [message_filter, setMessage_filter] = useState("");


    const [listCustomer, setListCustomer] = useState([]);
    const [listCustomer1, setListCustomer1] = useState([]);
    const [listRegion, setListRegion] = useState([]);
    const [listTypeOfBusiness, setListTypeOfBusiness] = useState([]);
    const [listPersonOfContact, setListPersonOfContact] = useState([]);
    const [listPersonOfContact1, setListPersonOfContact1] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [linePerPage, setlinePerPage] = useState(8);
    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const currentPosts = listCustomer.slice(indexOfFirstPost, indexOfLastPost);

    const currentPostsPOC = listPersonOfContact.slice(indexOfFirstPost, indexOfLastPost);
    const currentPostPOCforAcust = listPersonOfContact.slice(indexOfFirstPost, indexOfLastPost);

    const [customerOption, setCustomerOption] = useState(0);
    const [addCustomerP, setAddCustomer] = useState(0);
    const [addTypeOfBusinessP, setAddTypeOfBusiness] = useState(0);
    const [addRegionP, setAddRegion] = useState(0);
    const [editCustomerP, setEditCustomerP] = useState(0);
    const [addPersonOfContactToUser, setAddPersonOfContactToUser] = useState(0);
    const [listPersonOfContactForUser, setListPersonOfContactForUser] = useState(0);


    useEffect(() => {
        getUserPermissions();
        getListRegion();
        getListTypeOfBusiness();
        getListCustomer();
        getListPersonOfContact();



        setCustName("");
        setCustUsername("");
        setCustRegion("");
        setCustAddress("");
        setCustSize("");
        setCustPhoneNumber(0);
        setCustEmail("");
        setCustPwd("");
        setCustPermTBR(0);
        setCustPermOTR(0);
        setCustPermOIL(0);

        setCustCode(0);
        setCustCodeUpdate(0);


    }, [refresh]);



    const handleTypeClose = () => {
        setModalAddRegion(false);
        setModalAddTypeOfBusiness(false);
        setModalAddCustomer(false);
        setModalEditCustomer(false);
        setModalAddPersonOfContact(false);
        setModalPersonOfContact(false);
        setModalNewPassword(false);
        setModalFilter(false);


        setAddRegionText("");
        setAddTypeOfBusinessText("");

        setEdit_name("");
        setEdit_region("");
        setEdit_regionCode(0);
        setEdit_address("");
        setEdit_typeOfBusiness("");
        setEdit_size("");
        setEdit_phoneNumber(0);
        setEdit_email("");
        setEdit_permTBR(0);
        setEdit_permOTR(0);
        setEdit_permOIL(0);

        setListPersonOfContact([]);
        setListPersonOfContact1([]);

        setMessage_CustName("");
        setMessage_CustRegion("");
        setMessage_CustAddress("");
        setMessage_CustTypeOfBusiness("");
        setMessage_custSize("");
        setMessage_CustPhoneNumber("");
        setCustTypeOfBusiness(-1)


        setCustAddPOCName("");
        setCustAddPOCCode(0);
        setob(0);
        setRegion(0);

        if (refresh) {
            setRefresh(false);
        } else {
            setRefresh(true);
        }
    };

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const handleRegion = (e) => {
        setRegion(e.target.value);
    }

    const handleTypeOfBusiness = (e) => {
        setob(e.target.value);
    }

    function checkNumber(number) {
        var phone_number = '' + number;
        if (phone_number.length === 10 && phone_number[0] === '0') {
            if (phone_number[1] === "9" || phone_number[1] === "8")
                return (parseInt(phone_number.substring(1, 10), 10));
            else
                return (0)
        } else if (phone_number.length === 12) {
            if (phone_number.substring(0, 3) === "243") {
                if (phone_number[3] === "9" || phone_number[3] === "8")
                    return (parseInt(phone_number.substring(3, 13), 10));

                else
                    return (0)
            } else {
                return (0);
            }
        } else if (phone_number.length === 13) {
            if (phone_number.substring(0, 4) === "+243") {
                if (phone_number[4] === "9" || phone_number[4] === "8")
                    return (parseInt(phone_number.substring(4, 14), 10));

                else
                    return (0)
            } else {
                return (0)
            }
        } else if (phone_number.length === 14) {
            if (phone_number.substring(0, 5) === "00243") {
                if (phone_number[5] === "9" || phone_number[5] === "8")
                    return (parseInt(phone_number.substring(5, 15), 10));
                else
                    return (0)
            } else {
                return (0)
            }
        } else if (phone_number.length === 9) {
            if (phone_number[0] === "9" || phone_number[0] === "8") {
                return (parseInt(phone_number, 10));
            } else {
                return (0);
            }
        } else {
            return (0);
        }
    }


    const addCustomer = () => {
        var x = 0;

        if (custName === "") { setMessage_CustName("complete the name"); x = 1; }
        else setMessage_CustName("");
        if (custRegion === "") { setMessage_CustRegion("complete the region"); x = 1; }
        else setMessage_CustRegion("");
        if (custAddress === "") { setMessage_CustAddress("Complete the address"); x = 1; }
        else setMessage_CustAddress("");
        if (custTypeOfBusiness === "") { setMessage_CustTypeOfBusiness("Complete the type of business"); x = 1; }
        else setMessage_CustTypeOfBusiness("");
        if (custSize === "") { setMessage_custSize("Complete the size"); x = 1; }
        else setMessage_custSize("");
        if (checkNumber(custPhoneNumber) === 0) { setMessage_CustPhoneNumber("Complete the phone number"); x = 1 }
        else setMessage_CustPhoneNumber("");

        if (x === 0) {
            axios.post(url + "?p=addCustomer", {
                author: ReactSession.get('sales_id'),
                custName: custName,
                custRegion: custRegion,
                custAddress: custAddress,
                custTypeOfBusiness: custTypeOfBusiness,
                custSize: custSize,
                custPhoneNumber: checkNumber(custPhoneNumber),
                custEmail: custEmail,
                custPermTBR: custPermTBR,
                custPermOTR: custPermOTR,
                custPermOIL: custPermOIL
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("The customer has been added succesfully");
                    handleTypeClose();
                } else {
                    notifyError("error when we add the customer");
                }
            })
        }
    };

    const addRegion = () => {
        if (addRegionText === "") setMessage_AddRegionText("Complete the name of region");
        else setMessage_AddRegionText("")

        axios.post(url + "?p=addRegion", {
            author: ReactSession.get('sales_id'),
            addRegionText: addRegionText
        }, headers).then((response) => {
            if (response.data.status) {
                notifySuccess("The region has been added succesfully");
                handleTypeClose();
            } else {
                notifyError("Error when we add the new region");
            }
        })
    };

    const addTypeOfBusiness = () => {
        if (addTypeOfBusinessText === "") setMessage_AddTypeOfBusinessText("Complette the type of business");
        else setMessage_AddTypeOfBusinessText("");

        axios.post(url + "?p=addTypeOfBusiness", {
            author: ReactSession.get('sales_id'),
            addTypeOfBusinessText: addTypeOfBusinessText
        }, headers).then((response) => {
            if (response.data.status) {
                notifySuccess("The type of business has been added succesfully");
                handleTypeClose();
            } else {
                notifyError("Error when we add the new type of business");
            }
        })
    };

    const addLinkPOCandCUST = (contact_code) => {
        axios.post(url + "?p=addLinkPOCandCUST", {
            author: ReactSession.get('sales_id'),
            contact_code: contact_code,
            cust_code: custAddPOCCode
        }, headers).then((response) => {
            if (response.data.status) {
                notifySuccess("the link has been added succesfully");
                getListPersonOfContact();
            } else {
                notifyError("error when we add the new link")
            }
        })
    }

    const editCustomer = () => {
        var z = 0;

        if (edit_name === "") { setMessage_CustName("complete the name"); z = 1; }
        else setMessage_CustName("");
        if (region === "") { setMessage_CustRegion("complete the region"); z = 1; }
        else setMessage_CustRegion("");
        if (edit_address === "") { setMessage_CustAddress("Complete the address"); z = 1; }
        else setMessage_CustAddress("");
        if (tob === "") { setMessage_CustTypeOfBusiness("Complete the type of business"); z = 1; }
        else setMessage_CustTypeOfBusiness("");
        if (edit_size === "") { setMessage_custSize("Complete the size"); z = 1; }
        else setMessage_custSize("");
        if (checkNumber(edit_phoneNumber) === 0) { setMessage_CustPhoneNumber("Complete the phone number"); z = 1 }
        else setMessage_CustPhoneNumber("");

        if (region === 0) {
            var y = edit_regionCode;
        } else {
            var y = region;
        }
        if (tob === 0) {
            var x = edit_typeOfBusinessCode;
        } else {
            var x = tob;
        }

        if (z === 0) {
            axios.post(url + "?p=editCustomer", {
                author: ReactSession.get('sales_id'),
                cust_code: edit_code,
                cust_name: edit_name,
                cust_region: y,
                cust_address: edit_address,
                cust_typeOfBusiness: x,
                cust_size: edit_size,
                cust_phoneNumber: checkNumber(edit_phoneNumber),
                cust_email: edit_email,
                cust_permTBR: edit_permTBR,
                cust_permOTR: edit_permOTR,
                cust_permOIL: edit_permOIL
            }, headers).then((response) => {
                if (response.data.status) {
                    notifySuccess("the customer has been update succesfully");
                    handleTypeClose();
                } else {
                    notifyError("Error when we update the user");
                }
            })
        }
    }

    const getListRegion = () => {
        axios.post(url + "?p=getListRegion", {
            author: ReactSession.get('sales_id'),
            regCode: 0
        }, headers).then((response) => {
            if (response.data.status) {
                setListRegion(response.data.list);
            }
        })
    }

    const getEditedListRegion = (reg_code) => {
        axios.post(url + "?p=getListRegion", {
            author: ReactSession.get('sales_id'),
            regCode: reg_code
        }, headers).then((response) => {
            if (response.data.status) {
                setListRegion(response.data.list);
            }
        })
    }

    const getListTypeOfBusiness = () => {
        axios.post(url + "?p=getListTypeOfBusiness", {
            author: ReactSession.get('sales_id'),
            business_code: 0
        }, headers).then((response) => {
            if (response.data.status) {
                setListTypeOfBusiness(response.data.list);
            }
        })
    }

    const getEditedListTypeOfBusiness = (bCode) => {
        axios.post(url + "?p=getListTypeOfBusiness", {
            author: ReactSession.get('sales_id'),
            business_code: bCode
        }, headers).then((response) => {
            if (response.data.status) {
                setListTypeOfBusiness(response.data.list);
            }
        })
    }

    const getListCustomer = () => {
        axios.post(url + "?p=getCustomer", {
        }, headers).then((response) => {
            if (response.data.status) {
                setListCustomer(response.data.list);
                setListCustomer1(response.data.list);
            }
        })
    }

    const getDetailCust = (cust_code) => {
        axios.post(url + "?p=getDetailCust", {
            cust_code: cust_code
        }, headers).then((response) => {
            if (response.data.status) {

                setEdit_name(response.data.list[0].cust_name);
                setEdit_username(response.data.list[0].cust_username)
                setEdit_regionCode(response.data.list[0].reg_code);
                setEdit_region(response.data.list[0].reg_name);
                setEdit_address(response.data.list[0].cust_address);
                setEdit_typeOfBusiness(response.data.list[0].business_name);
                setEdit_typeOfBusinessCode(response.data.list[0].business_code);
                setEdit_size(response.data.list[0].cust_size);
                setEdit_phoneNumber(response.data.list[0].cust_number);
                setEdit_email(response.data.list[0].cust_email);
                setEdit_permTBR(response.data.list[0].cust_perm_TBR);
                setEdit_permOTR(response.data.list[0].cust_perm_OTR);
                setEdit_permOIL(response.data.list[0].cust_perm_OIL);
            }
        })
    }

    const getListPOC = () => {
        axios.post(url + "?p=getListPOC", {
        }, headers).then((response) => {
            if (response.data.status) {
                setListPersonOfContact(response.data.list);
            } else {
            }

        })
    }

    const getListPersonOfContact = () => {
        axios.post(url + "?p=getPersonOfContact", {
            task: "customer"
        }, headers).then((response) => {
            if (response.data.status) {
                setListPersonOfContact(response.data.list);
                setListPersonOfContact1(response.data.list);
            }
        })
    }

    const getListPOCforAcust = (customerCode) => {

        axios.post(url + "?p=getListPOCforAcust", {
            cust_code: customerCode
        }, headers).then((response) => {
            if (response.data.status) {
                setListPersonOfContact(response.data.list);
            }
        })
    }

    const deleteLink = (contactCode, customerCode) => {
        axios.post(url + "?p=deleteLink", {
            author: ReactSession.get('sales_id'),
            contact_code: contactCode,
            cust_code: customerCode
        }, headers).then((response) => {
            if (response.data.status) {
                notifySuccess("Thge link has been deleted succesfully");
                getListPOCforAcust(customerCode);
            } else {
                notifyError("Error when we delete the link");
            }
        })
    }

    function checkPerm(idService, task) {
        if (task === "add") {
            if (idService === "TBR") {
                if (custPermTBR === 0)
                    setCustPermTBR(1);
                else
                    setCustPermTBR(0);
            }
            else if (idService === "OTR") {
                if (custPermOTR === 0)
                    setCustPermOTR(1);
                else
                    setCustPermOTR(0);
            }
            else {
                if (custPermOIL === 0)
                    setCustPermOIL(1);
                else
                    setCustPermOIL(0);
            }
        } else {
            if (idService === "TBR") {
                if (edit_permTBR === 0)
                    setEdit_permTBR(1);
                else
                    setEdit_permTBR(0);
            }
            else if (idService === "OTR") {
                if (edit_permOTR === 0)
                    setEdit_permOTR(1);
                else
                    setEdit_permOTR(0);
            }
            else {
                if (edit_permOIL === 0)
                    setEdit_permOIL(1);
                else
                    setEdit_permOIL(0);
            }
        }

    }

    function beforEdit(bCode, cCode, rCode) {
        setEdit_code(cCode);
        getDetailCust(cCode);
        getEditedListRegion(rCode);
        getEditedListTypeOfBusiness(bCode);

        setModalEditCustomer(true);
    }

    function beforeAdd(cName, cCode,) {
        setCustAddPOCName(cName);
        setCustAddPOCCode(cCode);
        setModalAddPersonOfContact(true);
    }

    const filteringAddPOC = (customerCode, text) => {
        if (POCName !== "" && text !== "") {
            const filtered = listPersonOfContact1.filter(filtre => {
                return filtre.contact_name.toLowerCase().indexOf(text.toLowerCase()) >= 0;
            });
            setListPersonOfContact(filtered);
        } else {
            getListPersonOfContact(customerCode);
            setModalAddPersonOfContact(true);
        }

    };

    const filtering = () => {
        if (filterType === 1) {
            if (modalFilterText !== "") {
                const filtered = listCustomer.filter(filtre => {
                    return filtre.cust_name.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });
                setListCustomer(filtered);
                setModalFilter(false);
                paginate(1);
            } else {
                setModalFilter(true);
            }
        } else if (filterType === 2) {
            if (modalFilterText !== "") {
                const filtered = listCustomer.filter(filtre => {
                    return filtre.reg_name.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });

                setListCustomer(filtered);
                setModalFilter(false);
                paginate(1);
            } else {
                setModalFilter(true);
            }
        } else if (filterType === 3) {
            if (modalFilter !== "") {
                const filtered = listCustomer.filter(filtre => {
                    return filtre.cust_address.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });
                setListCustomer(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else if (filterType === 4) {
            if (modalFilter !== "") {
                const filtered = listCustomer.filter(filtre => {
                    return filtre.business_name.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });
                setListCustomer(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else if (filterType === 5) {
            if (modalFilter !== "") {
                const filtered = listCustomer.filter(filtre => {
                    return filtre.cust_size.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });
                setListCustomer(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else {
            if (modalFilterText !== "") {
                const filtered = listCustomer.filter(filtre => {
                    return filtre.cust_email.toLowerCase().indexOf(modalFilterText.toLowerCase()) >= 0;
                });

                setListCustomer(filtered);
                setModalFilter(false);
                paginate(1);
            }
        }

    };

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <a onClick={() => paginate(1)} href="#" className="">
                                    {"«"}
                                </a>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage == 1 ? (
                                <a href="#" className="" disabled>
                                    {"<"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage - 1)}
                                    href="#"
                                    className=""
                                >
                                    {"<"}
                                </a>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage == number ? (
                                    <a
                                        onClick={() => paginate(number)}
                                        href="#"
                                        className="bg-red"
                                    >
                                        {number}
                                    </a>
                                ) : (
                                    <a onClick={() => paginate(number)} href="#" className="">
                                        {number}
                                    </a>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage == totalPages ? (
                                <a href="#" className="" disabled>
                                    {">"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage + 1)}
                                    href="#"
                                    className=""
                                >
                                    {">"}
                                </a>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={() => paginate(totalPages)} href="#" className="">
                                    {"»"}
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);


    const getUserPermissions = () => {
        axios.post(url + "?p=getUserPermissions", {
            plan_author: ReactSession.get("sales_id"),
        }, headers).then((response) => {
            if (response.data.status) {
                response.data['result'].map((e) => {
                    switch (e.userPerm_code) {
                        case 402:
                            if (e.userPerm_value == 0) {
                                navigate("/home")
                            }
                            setCustomerOption(e.userPerm_value);
                            break;

                        case 405:

                            setAddCustomer(e.userPerm_value);

                            break;
                        case 406:

                            setAddTypeOfBusiness(e.userPerm_value);

                            break;
                        case 407:

                            setAddRegion(e.userPerm_value);

                            break;
                        case 408:

                            setEditCustomerP(e.userPerm_value);

                            break;
                        case 409:

                            setAddPersonOfContactToUser(e.userPerm_value);

                            break;
                        case 410:

                            setListPersonOfContactForUser(e.userPerm_value);

                            break;

                        default:
                            break;

                    }
                })
            } else {

            }
        })
    }

    function handleOnLoad() {
        axios.post(url + "?p=getPerm", {
            id: ReactSession.get('sales_id')
        }, headers).then((response) => {
            if (response.data.status) {

            }
        })
    }

    useEffect(() => {
        handleOnLoad()
    }, [])

    return (
        <>
            <Header />
            <Header_mobile />

            <Modal show={modalAddRegion} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Region</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                Region name
                                <span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="eq-subject"
                                onChange={(e) => {
                                    setAddRegionText(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_AddRegionText}
                            </div>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-success" onClick={addRegion}>Add region</button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalAddTypeOfBusiness} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add type of business</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                Type of business
                                <span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="eq-subject"
                                onChange={(e) => {
                                    setAddTypeOfBusinessText(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_AddTypeOfBusinessText}
                            </div>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-success" onClick={addTypeOfBusiness}>Add region</button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalAddCustomer} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="row g-3 ">
                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Customer Full Name<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="user-fullname"
                                onChange={(e) => {
                                    setCustName(e.target.value);
                                }}
                                value={custName}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustName}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Region<span className="text-danger pl-3">*</span>
                            </label>

                            <select
                                id="region"
                                onChange={(e) => {
                                    setCustRegion(e.target.value);
                                }}
                                value={custRegion}
                                name="categorie"
                                className="form-control rounded-0 cursor-pointer"
                            >
                                <option key={"a"} value={""}></option>
                                {
                                    listRegion ? (listRegion.map((d, index) => (
                                        <option key={index} value={d.reg_code}>
                                            {d.reg_name}
                                        </option>
                                    ))
                                    ) : (
                                        <>

                                        </>
                                    )
                                }
                            </select>

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustRegion}
                            </div>
                        </div>

                        <div className="form-group mb-1 col-md-6">
                            <label className="font-weight-bold">
                                Address <span className="text-danger pl-3">*</span>
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="text"
                                id="userAdd"
                                onChange={(e) => {
                                    setCustAddress(e.target.value);
                                }}
                                value={custAddress}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustAddress}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Type of business <span className="text-danger pl-3">*</span>
                                <span className="text-danger pl-3"></span>
                            </label>

                            <select
                                id="type"
                                onChange={(e) => {
                                    setCustTypeOfBusiness(e.target.value);
                                }}
                                value={custTypeOfBusiness}
                                name="type"
                                className="form-control rounded-0 cursor-pointer"
                            >
                                <option key={"a"} value={""}></option>
                                {
                                    listTypeOfBusiness ? (listTypeOfBusiness.map((d, index) => (
                                        <option key={index} value={d.business_code}>
                                            {d.business_name}
                                        </option>
                                    ))
                                    ) : (
                                        <>

                                        </>
                                    )
                                }
                            </select>

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustTypeOfBusiness}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Size <span className="text-danger pl-3">*</span>
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="text"
                                id="usersize"
                                onChange={(e) => {
                                    setCustSize(e.target.value);
                                }}
                                value={custSize}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustSize}
                            </div>
                        </div>

                        <div className="col-md-6 mb-1">
                            <label className="font-weight-bold">
                                Phone number
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="number"
                                id="userph"
                                onChange={(e) => {
                                    setCustPhoneNumber(e.target.value);
                                }}
                                value={custPhoneNumber}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustPhoneNumber}
                            </div>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                            <label className="font-weight-bold">
                                Email
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="email"
                                id="userMail"
                                onChange={(e) => {
                                    setCustEmail(e.target.value);
                                }}
                                value={custEmail}
                                name="subject"
                                className="form-control rounded-0"
                            />
                        </div>

                        <div className="container text-left">
                            <h4>Have right for the following after sale services</h4>
                            <div className="row text-left">
                                <div className="col">
                                    <input type="checkbox" value="TBR" onChange={(e) => { checkPerm(e.target.value, "add") }} />
                                    <label htmlFor="" className="ml-2"> TBR</label>
                                </div>
                                <div className="col">
                                    <input type="checkbox" value="OTR" onChange={(e) => { checkPerm(e.target.value, "add") }} />
                                    <label htmlFor="" className="ml-2">OTR</label>
                                </div>
                                <div className="col">
                                    <input type="checkbox" value="OIL" onChange={(e) => { checkPerm(e.target.value, "add") }} />
                                    <label htmlFor="" className="ml-2"> OIL</label><br />
                                </div>
                            </div>
                        </div>



                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleTypeClose} className="btn btn-secondary">Close</button>
                    <button type="button" onClick={addCustomer} className="btn btn-success">Add customer</button>

                </Modal.Footer>
            </Modal>
            <Modal show={modalEditCustomer} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Customer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="row g-3 ">
                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Customer Name<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="user-fullname"
                                onChange={(e) => {
                                    setEdit_name(e.target.value);
                                }}
                                value={edit_name}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustName}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Region<span className="text-danger pl-3">*</span>
                            </label>

                            <select
                                id="region"
                                onChange={handleRegion}
                                value={region}
                                name="categorie"
                                className="form-control rounded-0 cursor-pointer"
                            >
                                <option key={"a"} value={edit_regionCode}>{edit_region}</option>
                                {
                                    listRegion ? (listRegion.map((d, index) => (
                                        <option key={index} value={d.reg_code}>
                                            {d.reg_name}
                                        </option>
                                    ))
                                    ) : (
                                        <>

                                        </>
                                    )
                                }
                            </select>

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustRegion}
                            </div>
                        </div>

                        <div className="form-group mb-1 col-md-6">
                            <label className="font-weight-bold">
                                Address
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="text"
                                id="user"
                                onChange={(e) => {
                                    setEdit_address(e.target.value);
                                }}
                                value={edit_address}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustAddress}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Type of business
                                <span className="text-danger pl-3"></span>
                            </label>

                            <select
                                id="type"
                                onChange={handleTypeOfBusiness}
                                value={tob}
                                name="type"
                                className="form-control rounded-0 cursor-pointer"
                            >
                                <option key={"a"} value={edit_typeOfBusinessCode}>{edit_typeOfBusiness}</option>
                                {
                                    listTypeOfBusiness ? (listTypeOfBusiness.map((d, index) => (
                                        <option key={index} value={d.business_code}>
                                            {d.business_name}
                                        </option>
                                    ))
                                    ) : (
                                        <>

                                        </>
                                    )
                                }
                            </select>

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustTypeOfBusiness}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Size
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="text"
                                id="user"
                                onChange={(e) => {
                                    setEdit_size(e.target.value);
                                }}
                                value={edit_size}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustSize}
                            </div>
                        </div>

                        <div className="col-md-6 mb-1">
                            <label className="font-weight-bold">
                                Phone number
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="number"
                                id="user"
                                onChange={(e) => {
                                    setEdit_phoneNumber(e.target.value);
                                }}
                                value={edit_phoneNumber}
                                name="subject"
                                className="form-control rounded-0"
                            />

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_CustPhoneNumber}
                            </div>
                        </div>

                        <div className="form-group mb-4 col-md-6">
                            <label className="font-weight-bold">
                                Email
                                <span className="text-danger pl-3"></span>
                            </label>
                            <input
                                type="email"
                                id="user"
                                onChange={(e) => {
                                    setEdit_email(e.target.value);
                                }}
                                value={edit_email}
                                name="subject"
                                className="form-control rounded-0"
                            />
                        </div>

                        <div className="container text-left">
                            <h4>Have right for the following after sale services</h4>
                            <div className="row text-left">
                                <div className="col">
                                    {edit_permTBR === 0 ? (<input type="checkbox" value="TBR" onChange={(e) => { checkPerm(e.target.value, "edit") }} />) : (<input type="checkbox" value="TBR" onChange={(e) => { checkPerm(e.target.value, "edit") }} checked />)}

                                    <label htmlFor="" className="ml-2"> TBR</label>
                                </div>
                                <div className="col">
                                    {edit_permOTR === 0 ? (<input type="checkbox" value="OTR" onChange={(e) => { checkPerm(e.target.value, "edit") }} />) : (<input type="checkbox" value="OTR" onChange={(e) => { checkPerm(e.target.value, "edit") }} checked />)}

                                    <label htmlFor="" className="ml-2">OTR</label>
                                </div>
                                <div className="col">
                                    {edit_permOIL === 0 ? (<input type="checkbox" value="OIL" onChange={(e) => { checkPerm(e.target.value, "edit") }} />) : (<input type="checkbox" value="OIL" onChange={(e) => { checkPerm(e.target.value, "edit") }} checked />)}

                                    <label htmlFor="" className="ml-2"> OIL</label><br />
                                </div>
                            </div>
                        </div>



                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleTypeClose} className="btn btn-secondary">Close</button>
                    <button type="button" onClick={editCustomer} className="btn btn-success">Edit customer</button>

                </Modal.Footer>
            </Modal>
            <Modal show={modalAddPersonOfContact} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add person of contact for {custAddPOCName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                Search a person
                                <span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="eq-subject"
                                onChange={(e) => {

                                    setPOCName(e.target.value);
                                    filteringAddPOC(custCodeUpdate, e.target.value);
                                    if (e.target.value === "") { getListPersonOfContact(custAddPOCCode) }
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_AddRegionText}
                            </div>
                        </div>

                        <div className="h-250px overflow-auto">
                            <table id="customers max-h-300px" className="table ">
                                <thead className="position-fixe">
                                    <tr>
                                        <th scope="col" className="text-left">
                                            Name
                                        </th>

                                        <th scope="col" className="text-left">
                                            Email
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody id="resultdata">
                                    {listPersonOfContact ? (
                                        listPersonOfContact.map((d, index) => (
                                            <tr
                                                key={index}
                                                className="text-info"
                                            >
                                                <td id="tb-assigned-to" className="text-left">
                                                    {d.contact_name}
                                                </td>

                                                <td id="tb-created-date" className="text-left">
                                                    <label className={d.contact_email === "" ? "text-danger" : ""}>
                                                        {d.contact_email === "" ? "No Email" : d.contact_email}
                                                    </label>
                                                </td>
                                                <td>
                                                    {
                                                        d.cust_code > 0 ? (
                                                            <></>
                                                        ) : (
                                                            <a
                                                                onClick={() => { addLinkPOCandCUST(d.contact_code) }}
                                                                href="#"
                                                                data-toggle="modal"
                                                                data-target="#ticketidModal"
                                                            >
                                                                <i className="fa fa-plus"></i>
                                                            </a>
                                                        )
                                                    }

                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={11} className="text-center pt-20 fs-16">
                                                No data found
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary w-25" onClick={handleTypeClose}>Close</button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalPersonOfContact} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Person of contact for {custAddPOCName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="h-250px overflow-auto">
                        <table id="customers max-h-300px" className="table ">
                            <thead className="position-fixe">
                                <tr>
                                    <th scope="col" className="text-left">
                                        Name
                                    </th>

                                    <th scope="col" className="text-left">
                                        Email
                                    </th>
                                    <th>Phone number</th>
                                </tr>
                            </thead>
                            <tbody id="resultdata">
                                {listPersonOfContact ? (
                                    listPersonOfContact.map((d, index) => (
                                        <tr
                                            className="text-info"
                                            key={d.contact_code}
                                        >
                                            <td id="tb-assigned-to" className="text-left">
                                                {d.contact_name}
                                            </td>
                                            <td id="tb-created-date" className="text-left">
                                                <label className={d.contact_email === "" ? "text-danger" : ""}>
                                                    {d.contact_email === "" ? "No Email" : d.contact_email}
                                                </label>
                                            </td>
                                            <td>
                                                {d.contact_number}
                                            </td>
                                            <td>
                                                {d.cust_code > 0 ? (
                                                    <a
                                                        onClick={() => { deleteLink(d.contact_code, custCodeUpdate) }}
                                                        href="#"
                                                        data-toggle="modal"
                                                        data-target="#ticketidModal"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </a>
                                                ) : (
                                                    <></>
                                                )}

                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={11} className="text-center pt-20 fs-16">
                                            No data found
                                        </td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary w-20" onClick={handleTypeClose}>Close</button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalNewPassword} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Reset password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                New Password
                                <span className="text-danger pl-3">*</span>
                            </label>
                            <div id="ticketprio-error" className="text-danger pt-2">
                                <label>The new password is {custNewPassword}</label>
                            </div>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-warning" onClick={handleTypeClose}>Next</button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalFilter} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                {
                                    filterType === 1 ? (
                                        "Name"
                                    ) : filterType === 2 ? (
                                        "Region"
                                    ) : filterType === 3 ? (
                                        "Address"
                                    ) : filterType === 4 ? (
                                        "Type of business"
                                    ) : filterType === 5 ? (
                                        "Size"
                                    ) : (
                                        "Email"
                                    )
                                }
                                <span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="eq-subject"
                                onChange={(e) => {
                                    setModalFilterText(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_filter}
                            </div>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-success" onClick={filtering}>Add filter</button>
                </Modal.Footer>
            </Modal>


            <div
                className="d-flex flex-column flex-column-fluid "
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}
            >

                <div className="col-xl-12 center-block " align="center" style={{ backgroundColor: "#eef0f8" }} >
                    <div className="col-xl-8 bg-white mt-50 center-block min-h-650px" align="center" >
                        <div className="row d-flex pt-10 w-100 h-100px" style={{ marginLeft: "0.8rem" }}>
                            <div className="col-12 col-lg-6 col-sm-12 center-block">
                                <h3 className="float-left pt-2 pr-3">List of customer </h3>
                                <span className="float-left">
                                    <BiRefresh
                                        onClick={() => {
                                            getListCustomer();
                                        }}
                                        className="cursor-pointer display-4"
                                    />
                                </span>
                            </div>
                            <div className="col-12 col-lg-6 col-sm-12  ">
                                {/* <div className="col-4 col-sm-4">

                                        </div> */}
                                {addCustomerP === 1 ? (
                                    <button
                                        onClick={() => setModalAddCustomer(true)}
                                        id="crticket-btn"
                                        className="btn btn-defaut text-white bg-red font-weight-bold float-right mr-5"
                                        data-toggle="modal"
                                        data-target="#addticketModal"
                                    >
                                        Add customer
                                    </button>
                                ) : (
                                    <></>
                                )}

                                {addTypeOfBusinessP === 1 ? (
                                    <button
                                        onClick={() => setModalAddTypeOfBusiness(true)}
                                        id="crticket-btn"
                                        className="btn btn-success text-white bg-red font-weight-bold float-right mr-1"
                                        data-toggle="modal"
                                        data-target="#addticketModal"
                                    >
                                        Add type of business
                                    </button>
                                ) : (<></>)}

                                {addRegionP === 1 ? (
                                    <button
                                        onClick={() => setModalAddRegion(true)}
                                        id="crticket-btn"
                                        className="btn btn-info text-white bg-red font-weight-bold float-right mr-1 "
                                        data-toggle="modal"
                                        data-target="#addticketModal"
                                    >
                                        Add region
                                    </button>
                                ) : (<></>)}
                            </div>



                        </div>

                        <div className="h-550px overflow-auto">
                            <table id="customers max-h-300px" className="table ">
                                <thead className="position-fixe ">
                                    <tr>
                                        <th scope="col" className="w-25px"></th>
                                        <th scope="col" className="w-25px">
                                            #
                                        </th>

                                        <th scope="col" className="text-left">
                                            Name
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketidModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(1); setListCustomer(listCustomer1); }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Region
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketidModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(2); setListCustomer(listCustomer1); }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Adress
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(3); setListCustomer(listCustomer1); }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Type of business
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(4); setListCustomer(listCustomer1); }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Size
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(5); setListCustomer(listCustomer1); }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Email
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(6); setListCustomer(listCustomer1); }}></i>
                                            </a>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="resultdata">
                                    {
                                        currentPosts ? (
                                            currentPosts.map((d, index) => (
                                                <tr
                                                    className="text-info"
                                                    key={d.cust_code}
                                                >
                                                    <Dropdown className="dropdown">
                                                        <Dropdown.Toggle
                                                            variant="dropdown-basic"
                                                            className="w-60px py-0 text-white p-0 m-0"
                                                        >
                                                            <p
                                                                className="dropdown dropdown-inline bg-light2 py-0 rounded"
                                                                data-toggle="dropdown"

                                                            >
                                                                <a

                                                                    type="button"
                                                                    className="btn btn-light2 btn-icon py-0 btn-sm"
                                                                >
                                                                    <i className="ki ki-bold-more-hor"></i>
                                                                </a>
                                                            </p>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu className="">
                                                            {editCustomerP === 1 ? (
                                                                <Dropdown.Item
                                                                    href="#"
                                                                    onClick={() => { beforEdit(d.business_code, d.cust_code, d.reg_code); }}
                                                                    className="dropdown-item border-bottom border-top"
                                                                >
                                                                    <i className="fa fa-edit"></i>{" "}
                                                                    <span className="pl-3">Edit Customer</span>
                                                                </Dropdown.Item>
                                                            ) : (<></>)}

                                                            {addPersonOfContactToUser === 1 ? (
                                                                <Dropdown.Item
                                                                    href="#"
                                                                    onClick={() => { getListPOC(); beforeAdd(d.cust_name, d.cust_code); }}
                                                                    className="dropdown-item border-bottom border-top"
                                                                >
                                                                    <i className="fa fa-user-plus"></i>{" "}
                                                                    <span className="pl-3">Add person of contact</span>
                                                                </Dropdown.Item>
                                                            ) : (<></>)}

                                                            {listPersonOfContactForUser === 1 ? (
                                                                <Dropdown.Item
                                                                    href="#"
                                                                    onClick={() => { setCustAddPOCName(d.cust_name); setModalPersonOfContact(true); getListPOCforAcust(d.cust_code); setCustCodeUpdate(d.cust_code) }}
                                                                    className="dropdown-item border-bottom border-top"
                                                                >
                                                                    <i className="fa fa-list-alt"></i>{" "}
                                                                    <span className="pl-3">Person to contact</span>
                                                                </Dropdown.Item>
                                                            ) : (<></>)}


                                                        </Dropdown.Menu>
                                                    </Dropdown>


                                                    <td id="tb-assigned-to" className="text-left  text-dark">
                                                        {d.cust_code}
                                                    </td>

                                                    <td id="tb-assigned-to" className="text-left  text-dark">
                                                        {d.cust_name}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left  text-dark">
                                                        {d.reg_name}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left  text-dark">
                                                        {d.cust_address}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left  text-dark">
                                                        {d.business_name}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left  text-dark">
                                                        {d.cust_size}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left  text-dark">
                                                        {d.cust_email}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={11} className="text-center pt-20 fs-16">
                                                    No data found
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>


                    </div>
                    <div className="card-footer pt-2 pb-2 col-8 mb-0 bg-white">
                        <div className="d-flex justify-content-center">
                            <Pagination
                                postsPerPage={linePerPage}
                                totalPosts={listCustomer.length}
                                paginate={paginate}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Customers;