import React, { Component, useContext, useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";

import logo from "../Assets/img/auto-logo.jpg";
import { ReactSession } from "react-client-session";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useAsyncError, useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { MDBCheckbox } from "mdb-react-ui-kit";

var bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);


var bcrypt = require("bcryptjs");

function Header_mobile() {
    const url = process.env.REACT_APP_URL;
    const headers = JSON.parse(process.env.REACT_APP_CONFIG);
    const [displayMenu, setDisplayMenu] = useState("d-none");

    const displayMenuHandle = () => {
        if (displayMenu == "d-none") {
            setDisplayMenu("d-flex");
        } else {
            setDisplayMenu("d-none");
        }
    };
    const navigate = useNavigate();
    const [oldpassword, setOldpassword] = useState();
    const [newpassword, setNewpassword] = useState();
    const [confirmpassword, setConfirmpassword] = useState();
    const [showChangePassword, setShowChangePassword] = useState(false);

    const [Messageoldpassword, setMessageOldpassword] = useState();
    const [Messagenewpassword, setMessageNewpassword] = useState();
    const [vuepassword, setVuepassword] = useState(false);
    const [Messageconfirmpassword, setMessageConfirmpassword] = useState();


    const [customerOption, setCustomerOption] = useState(0);
    const [userOption, setUserOption] = useState(0);
    const [personOfCOntactOption, setPersonOfCOntactOption] = useState(0);
    const [viewLogs, setViewLogs] = useState(0);


    const handleLogout = () => {
        ReactSession.remove("sales_id");
        ReactSession.remove("sales_username");
        ReactSession.remove("sales_mail");

        localStorage.clear();
        navigate("/");
    };

    const check_verif = () => {
        if (vuepassword === false) {
            setVuepassword(true);
        } else {
            setVuepassword(false);
        }
    };

    useEffect(() => {
        getUserPermissions();
    }, []);

    const change_password = () => {
        let verif = 0;
        if (oldpassword === "") {
            verif = 1;
            setMessageOldpassword("Old password is empty");
        }
        if (newpassword === "") {
            setMessageNewpassword("New password is empty");
            verif = 1;
        }
        if (confirmpassword === "") {
            verif = 1;
            setMessageConfirmpassword("You must confirm the new password");
        }

        if (verif === 0) {
            if (newpassword === confirmpassword) {
                var hash = bcrypt.hashSync(newpassword, salt);
                axios.post(url + "?p=resetPassword", {
                    username: ReactSession.get("sales_username"),
                    password: hash,
                    id: ReactSession.get("sales_id"),
                }, headers).then((response) => {
                    if (response.data.status === true) {
                        notifySuccess("Password has been changed");
                        setShowChangePassword(false);
                    }
                })
            } else {
                setMessageConfirmpassword(
                    "The first password and the second password do not match"
                );
            }
        }
    };

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const handleModelClosechangePassword = () => {
        setShowChangePassword(false);
        setOldpassword("");
        setNewpassword("");
        setConfirmpassword("");
        setMessageOldpassword("");
        setMessageNewpassword("");
        setMessageConfirmpassword("");
    };

    const getUserPermissions = () => {
        axios.post(url + "?p=getUserPermissions", {
            plan_author: ReactSession.get("sales_id"),
        }, headers).then((response) => {
            if (response.data.status) {
                response.data['result'].map((e) => {
                    switch (e.userPerm_code) {
                        case 402:
                            setCustomerOption(e.userPerm_value);
                            break;
                        case 403:

                            setUserOption(e.userPerm_value);

                            break;
                        case 404:

                            setPersonOfCOntactOption(e.userPerm_value);

                            break;
                        case 417:

                            setViewLogs(e.userPerm_value);

                            break;
                        default:
                            break;

                    }
                })
            } else {

            }
        })
    }

    return (
        <>
            <Modal
                show={showChangePassword}
                onHide={handleModelClosechangePassword}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group col-xl-12 ">
                            <label className="font-weight-bold">
                                Old password<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="old-password"
                                type={vuepassword ? "text" : "password"}
                                onChange={(e) => {
                                    setOldpassword(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="cstm-code-error" className="text-danger pt-2">
                                {Messageoldpassword}
                            </div>
                        </div>

                        <div className="form-group col-xl-12 ">
                            <label className="font-weight-bold">
                                New password<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="new-password"
                                type={vuepassword ? "text" : "password"}
                                onChange={(e) => {
                                    setNewpassword(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="cstm-code-error" className="text-danger pt-2">
                                {Messagenewpassword}
                            </div>
                        </div>

                        <div className="form-group col-xl-12 ">
                            <label className="font-weight-bold">
                                Confirm password<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="old-password"
                                type={vuepassword ? "text" : "password"}
                                onChange={(e) => {
                                    setConfirmpassword(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="cstm-code-error" className="text-danger pt-2">
                                {Messageconfirmpassword}
                            </div>
                        </div>
                        <MDBCheckbox
                            className="ml-30"
                            name="flexCheck"
                            onChange={check_verif}
                            value={vuepassword}
                            id="shwpssw"
                            label="Show password"
                        />
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModelClosechangePassword}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={change_password}>
                        Change password
                    </Button>
                </Modal.Footer>
            </Modal>



            <div
                id="kt_header_mobile"
                className={"header-mobile bg-white d-print-none "}
            >
                <div className={"dropdown pt-0 mr-1"}>
                    <Dropdown className="">
                        <Dropdown.Toggle
                            variant="dropdown-basic"
                            className="w-auto h-40px text-white pt-0 pb-0"
                        >
                            <div
                                className="btn p-0 ml-4"
                                id="kt_header_mobile_toggle"
                                onClick={() => displayMenuHandle()}
                            >
                                <span>
                                    <i className="fas fa-bars"></i>
                                </span>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="w-180px">
                            <Dropdown.Item
                                href="#"
                                className="navi-link border-bottom"
                                onClick={() => navigate('/home')}
                            >
                                <span className="text-dark-75 font-weight-bolder pl-5">
                                    Home
                                </span>
                                <i className="menu-arrow pb-2"></i>
                            </Dropdown.Item>
                            <Dropdown.Item
                                href="#"
                                className="navi-link border-bottom"
                                onClick={() => navigate("/Planning")}
                            >
                                <span className="text-dark-75 font-weight-bolder pl-5">
                                    Planning
                                </span>
                                <i className="menu-arrow pb-2"></i>
                            </Dropdown.Item>
                            {customerOption === 1 ? (
                                <Dropdown.Item onClick={() => navigate("/customers")}>
                                    <span className="text-dark-75 font-weight-bolder pl-5">
                                        Customers

                                    </span>
                                    <i className="menu-arrow pb-2"></i>

                                    <i className="menu-arrow pb-2"></i>
                                </Dropdown.Item>
                            ) : ""}

                            {userOption === 1 ? (
                                <Dropdown.Item
                                    href="#"
                                    className="navi-link "
                                    onClick={() => navigate("/users")}
                                >
                                    <span className="text-dark-75 font-weight-bolder pl-5">
                                        Users
                                    </span>
                                    <i className="menu-arrow pb-2"></i>
                                </Dropdown.Item>
                            ) : ""}

                            {personOfCOntactOption === 1 ? (
                                <Dropdown.Item
                                    href="#"
                                    className="navi-link"
                                    onClick={() => navigate("/Pcontact")}
                                >
                                    <span className="text-dark-75 font-weight-bolder pl-5">
                                        Person of contact
                                    </span>
                                    <i className="menu-arrow pb-2"></i>
                                </Dropdown.Item>
                            ) : ""}

                            {viewLogs === 1 ? (
                                <Dropdown.Item
                                    href="#"
                                    className="navi-link border-bottom"
                                    onClick={() => navigate("/Logs")}
                                >
                                    <span className="text-dark-75 font-weight-bolder pl-5">
                                        Logs
                                    </span>
                                    <i className="menu-arrow pb-2"></i>
                                </Dropdown.Item>
                            ) : ""}


                            <Dropdown.Item
                                href="#"
                                className="navi-link"
                                onClick={() => handleLogout("fr")}
                            >
                                <span className="text-dark-75 font-weight-bolder pl-5">
                                    Logout
                                </span>
                                <i className="menu-arrow pb-2"></i>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <a href="/home">
                    <img alt="Logo" src={logo} className="logo-default max-h-30px" />
                </a>

                <div className="d-flex">
                    {/* <Language paddingTop="pt-0" /> */}
                    <div className="w-full dropdown mr-0 pt-0">
                        <a className="menu-link">
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="dropdown-basic"
                                    className="w-100 h-40px text-white p-0 m-0 pt-0 pb-0"
                                >
                                    <div
                                        className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-0"
                                        id="open-notification"
                                    >
                                        <i className="far fa-bell text-dark-75 mr-2"></i>
                                        <span className="label label-rounded label-danger">
                                            <div id="unreadnot">0</div>
                                        </span>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="w-full">
                                    <Dropdown.Item href="#">
                                        <form className="p-0">
                                            <div className="d-flex pt-0 pb-4 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                                                <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-15 font-weight-bolder w-200px">
                                                    {"Notifications"}
                                                </div>
                                            </div>

                                            <div className="scroll scroll-push text-center h-100px">
                                                <div id="notificationcontent" className="pt-11"></div>
                                            </div>
                                        </form>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </a>
                    </div>
                </div>
            </div>

            {/* Right Side */}
        </>
    );
}

export default Header_mobile;
